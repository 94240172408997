/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { ProviderName } from './providerName';
import type { ProviderNameKey } from './providerNameKey';
import type { ServiceNameKey } from './serviceNameKey';
import type { ProductCategoryName } from './productCategoryName';

export interface ExpenseMeta {
  product_name_key?: string;
  created_by_first_name?: string;
  created_by_last_name?: string;
  created_by_email?: string;
  user_first_name?: string;
  user_last_name?: string;
  user_email?: string;
  membership_is_suspended?: boolean;
  provider_icon_url?: string;
  provider_name?: ProviderName;
  provider_name_key?: ProviderNameKey;
  service_name_key?: ServiceNameKey;
  /** name of the category */
  category_name_key?: ProductCategoryName;
  /** name of the program */
  program_name?: string;
  /** use for reviewer only, is true if expense belongs to reviewer's team */
  readonly can_be_edited?: boolean;
}
