/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { PaymentMethodType } from './paymentMethodType';
import type { PaymentMethodStatus } from './paymentMethodStatus';
import type { PaymentMethodCreditCard } from './paymentMethodCreditCard';

export interface PaymentMethod {
  id: Uuid;
  user_id: string;
  payment_method_type: PaymentMethodType;
  status: PaymentMethodStatus;
  credit_card?: PaymentMethodCreditCard;
}
