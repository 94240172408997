/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { Service } from './service';
import type { PolicyCondition } from './policyCondition';

export interface PolicyConflict {
  policy_id?: Uuid;
  services?: Service[];
  condition?: PolicyCondition;
}
