/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  ReportGraphDataGetResponse,
  ReportGetReportCo2GraphDataParams,
  ReportSummaryDataGetResponse,
  ReportGetReportCo2SummaryDataParams,
  ReportExpensesSummaryDataGetResponse,
  ReportGetReportExpensesSummaryDataParams,
} from '../../models';
import { customInstance } from '../../config';

export const reportGetReportCo2GraphData = (
  reportType: 'CO2' | 'EXPENSES',
  params?: ReportGetReportCo2GraphDataParams,
  signal?: AbortSignal,
) => {
  return customInstance<ReportGraphDataGetResponse>({
    url: `/web/v2/reports/${reportType}/graphs`,
    method: 'get',
    params,
    signal,
  });
};

export const getReportGetReportCo2GraphDataQueryKey = (
  reportType: 'CO2' | 'EXPENSES',
  params?: ReportGetReportCo2GraphDataParams,
) => [`/web/v2/reports/${reportType}/graphs`, ...(params ? [params] : [])];

export type ReportGetReportCo2GraphDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof reportGetReportCo2GraphData>>
>;
export type ReportGetReportCo2GraphDataQueryError = unknown;

export const useReportGetReportCo2GraphData = <
  TData = Awaited<ReturnType<typeof reportGetReportCo2GraphData>>,
  TError = unknown,
>(
  reportType: 'CO2' | 'EXPENSES',
  params?: ReportGetReportCo2GraphDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof reportGetReportCo2GraphData>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getReportGetReportCo2GraphDataQueryKey(reportType, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof reportGetReportCo2GraphData>>
  > = ({ signal }) => reportGetReportCo2GraphData(reportType, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof reportGetReportCo2GraphData>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!reportType,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const reportGetReportCo2SummaryData = (
  params?: ReportGetReportCo2SummaryDataParams,
  signal?: AbortSignal,
) => {
  return customInstance<ReportSummaryDataGetResponse>({
    url: `/web/v2/reports/co2/summaries`,
    method: 'get',
    params,
    signal,
  });
};

export const getReportGetReportCo2SummaryDataQueryKey = (
  params?: ReportGetReportCo2SummaryDataParams,
) => [`/web/v2/reports/co2/summaries`, ...(params ? [params] : [])];

export type ReportGetReportCo2SummaryDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof reportGetReportCo2SummaryData>>
>;
export type ReportGetReportCo2SummaryDataQueryError = unknown;

export const useReportGetReportCo2SummaryData = <
  TData = Awaited<ReturnType<typeof reportGetReportCo2SummaryData>>,
  TError = unknown,
>(
  params?: ReportGetReportCo2SummaryDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof reportGetReportCo2SummaryData>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getReportGetReportCo2SummaryDataQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof reportGetReportCo2SummaryData>>
  > = ({ signal }) => reportGetReportCo2SummaryData(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof reportGetReportCo2SummaryData>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const reportGetReportExpensesSummaryData = (
  params?: ReportGetReportExpensesSummaryDataParams,
  signal?: AbortSignal,
) => {
  return customInstance<ReportExpensesSummaryDataGetResponse>({
    url: `/web/v2/reports/expenses/summaries`,
    method: 'get',
    params,
    signal,
  });
};

export const getReportGetReportExpensesSummaryDataQueryKey = (
  params?: ReportGetReportExpensesSummaryDataParams,
) => [`/web/v2/reports/expenses/summaries`, ...(params ? [params] : [])];

export type ReportGetReportExpensesSummaryDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof reportGetReportExpensesSummaryData>>
>;
export type ReportGetReportExpensesSummaryDataQueryError = unknown;

export const useReportGetReportExpensesSummaryData = <
  TData = Awaited<ReturnType<typeof reportGetReportExpensesSummaryData>>,
  TError = unknown,
>(
  params?: ReportGetReportExpensesSummaryDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof reportGetReportExpensesSummaryData>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getReportGetReportExpensesSummaryDataQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof reportGetReportExpensesSummaryData>>
  > = ({ signal }) => reportGetReportExpensesSummaryData(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof reportGetReportExpensesSummaryData>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};
