import { Text } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { capitalize } from '@libs/core/utils';
import { Table, usePager } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useDrawer, useTable } from '@libs/ui/components/table/table.utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useProgramGroupsGet } from '@libs/api-v2/endpoints/programs/programs';
import { useIcon } from '@libs/core/theme/utils';
import { PoliciesDrawer } from '../../employee/details/components/policies-drawer';

type OrganisationDetailsProgramGroupsProps = {
  programId?: string;
  programName?: string;
};

export const ProgramGroupsTable = ({
  programId,
  programName,
}: OrganisationDetailsProgramGroupsProps) => {
  const { t } = useTranslation();
  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();
  const { setSearchQuery, useQueryParams } = useTable({
    setPageNumber,
    setPageSize,
  });
  const EyeIcon = useIcon('Eye');
  const { drawerHandler, isOpen, onClose, activeDrawerIndex } = useDrawer();

  const submitHandler = ({ q }) => {
    setSearchQuery(q);
  };

  const {
    // is false when fetching cached values
    isLoading,
    refetch,
    data: programgroups,
    // defaults to true so long as enabled is set to false
    // is true if the query is currently fetching, including background fetching
    isFetching,
  } = useProgramGroupsGet(
    programId,
    {
      page_number: pageNumber,
      page_size: pageSize,
    },
    { query: { keepPreviousData: true } },
  );

  useQueryParams({
    pageSize,
    pageNumber,
    setPageSize,
    refetch,
    totalPages: programgroups?.meta.total_pages,
  });

  const headers: TableHeaders = [
    {
      name: 'name',
      title: t(i18nKeys.common.group),
    },
  ];

  return (
    <>
      <Table onSubmit={submitHandler} headers={headers}>
        <Table.TBody
          isLoading={(isLoading || isFetching) && !programgroups}
          colSpan={headers.length}
          isEmpty={
            !isLoading &&
            !isFetching &&
            (programgroups?.data.length === 0 || !programgroups)
          }
        >
          {programgroups?.data.map(
            (
              { program_id, group_id, name, is_monthly_limit_enabled },
              index,
            ) => (
              <Table.TR key={group_id} selectable index={group_id}>
                <Table.TD>
                  <Text size="Body2">{capitalize(name)} </Text>
                </Table.TD>
                <Table.Action
                  actions={[
                    {
                      onClick: () =>
                        drawerHandler({
                          index,
                        }),
                      icon: <EyeIcon width="2rem" height="2rem" />,
                    },
                  ]}
                />
              </Table.TR>
            ),
          )}
        </Table.TBody>
      </Table>
      {isOpen && programgroups?.data[activeDrawerIndex] && (
        <PoliciesDrawer
          programId={programgroups?.data[activeDrawerIndex]?.program_id}
          groupId={programgroups?.data[activeDrawerIndex]?.group_id}
          programName={programName}
          groupName={programgroups?.data[activeDrawerIndex]?.name}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};
