/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { QueryMembershipIdParameter } from './component-parameters/queryMembershipIdParameter';
import type { QueryDateFromParameter } from './component-parameters/queryDateFromParameter';

export type ProgramGroupLimitSimulationParams = {
  membership_id?: QueryMembershipIdParameter;
  date_from?: QueryDateFromParameter;
};
