/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export interface Audit {
  readonly created_at?: string;
  readonly updated_at?: string;
}
