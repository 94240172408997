/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';

export interface Team {
  readonly id?: Uuid;
  /** Technical creation timestamp of the team. */
  readonly created_at?: string;
  /** Technical update timestamp of the team. */
  readonly updated_at?: string;
  name?: string;
  readonly organisation_id?: Uuid;
  managers?: Uuid[];
  hr_managers?: Uuid[];
}
