/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ReportAggregation =
  typeof ReportAggregation[keyof typeof ReportAggregation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportAggregation = {
  ALL: 'ALL',
  PROGRAM: 'PROGRAM',
  PROGRAM_GROUP: 'PROGRAM_GROUP',
  SERVICE: 'SERVICE',
  CATEGORY: 'CATEGORY',
  PROVIDER: 'PROVIDER',
  EMPLOYEE: 'EMPLOYEE',
  GROUP: 'GROUP',
} as const;
