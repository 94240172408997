/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Product } from './product';
import type { ProductWithMetaAllOf } from './productWithMetaAllOf';

export type ProductWithMeta = Product & ProductWithMetaAllOf;
