/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type RefundIssuerParameter =
  typeof RefundIssuerParameter[keyof typeof RefundIssuerParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefundIssuerParameter = {
  NONE: 'NONE',
  ORGANISATION: 'ORGANISATION',
  EMPLOYEE: 'EMPLOYEE',
  RESELLER: 'RESELLER',
  PROVIDER: 'PROVIDER',
} as const;
