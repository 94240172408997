/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { QueryOrganisationIdParameter } from './component-parameters/queryOrganisationIdParameter';
import type { QueryProgramIdParameter } from './component-parameters/queryProgramIdParameter';
import type { QueryTeamIdParameter } from './component-parameters/queryTeamIdParameter';
import type { QueryQParameter } from './component-parameters/queryQParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';
import type { QueryRegistrationStatusParameter } from './queryRegistrationStatusParameter';
import type { QueryRolesParameter } from './queryRolesParameter';
import type { QuerySingleRoleParameter } from './querySingleRoleParameter';
import type { QueryIsSuspendedParameter } from './queryIsSuspendedParameter';
import type { NoTeamParameter } from './noTeamParameter';
import type { NoGroupParameter } from './noGroupParameter';
import type { QueryHasMembershipActionParameter } from './queryHasMembershipActionParameter';

export type RegistrationsGetParams = {
  organisation_id?: QueryOrganisationIdParameter;
  program_id?: QueryProgramIdParameter;
  team_id?: QueryTeamIdParameter;
  q?: QueryQParameter;
  page_number?: QueryPageNumberParameter;
  page_size?: QueryPageSizeParameter;
  status?: QueryRegistrationStatusParameter;
  role?: QueryRolesParameter;
  single_role?: QuerySingleRoleParameter;
  is_suspended?: QueryIsSuspendedParameter;
  no_team?: NoTeamParameter;
  no_group?: NoGroupParameter;
  has_membership_action?: QueryHasMembershipActionParameter;
};
