/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  PoliciesListResponse,
  ListProgramExpensePoliciesParams,
  Policy,
  UpsertPolicyRequest,
  CheckPolicyResponse,
  CheckPolicyRequest,
} from '../../models';
import { customInstance } from '../../config';

/**
 * Returns the policies for a program or program group:
- Program ID provided: Returns all policies of the PROGRAM level
- Program ID and Group ID provided: Returns all policies of the PROGRAM_GROUP and SERVICE levels

 */
export const listProgramExpensePolicies = (
  params: ListProgramExpensePoliciesParams,
  signal?: AbortSignal,
) => {
  return customInstance<PoliciesListResponse>({
    url: `/web/v2/policies`,
    method: 'get',
    params,
    signal,
  });
};

export const getListProgramExpensePoliciesQueryKey = (
  params: ListProgramExpensePoliciesParams,
) => [`/web/v2/policies`, ...(params ? [params] : [])];

export type ListProgramExpensePoliciesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listProgramExpensePolicies>>
>;
export type ListProgramExpensePoliciesQueryError = unknown;

export const useListProgramExpensePolicies = <
  TData = Awaited<ReturnType<typeof listProgramExpensePolicies>>,
  TError = unknown,
>(
  params: ListProgramExpensePoliciesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listProgramExpensePolicies>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListProgramExpensePoliciesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listProgramExpensePolicies>>
  > = ({ signal }) => listProgramExpensePolicies(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof listProgramExpensePolicies>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const createProgramPolicy = (
  upsertPolicyRequest: UpsertPolicyRequest,
) => {
  return customInstance<Policy>({
    url: `/web/v2/policies`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: upsertPolicyRequest,
  });
};

export type CreateProgramPolicyMutationResult = NonNullable<
  Awaited<ReturnType<typeof createProgramPolicy>>
>;
export type CreateProgramPolicyMutationBody = UpsertPolicyRequest;
export type CreateProgramPolicyMutationError = unknown;

export const useCreateProgramPolicy = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createProgramPolicy>>,
    TError,
    { data: UpsertPolicyRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createProgramPolicy>>,
    { data: UpsertPolicyRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createProgramPolicy(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof createProgramPolicy>>,
    TError,
    { data: UpsertPolicyRequest },
    TContext
  >(mutationFn, mutationOptions);
};
export const checkPolicy = (checkPolicyRequest: CheckPolicyRequest) => {
  return customInstance<CheckPolicyResponse>({
    url: `/web/v2/policies/check_policy`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: checkPolicyRequest,
  });
};

export type CheckPolicyMutationResult = NonNullable<
  Awaited<ReturnType<typeof checkPolicy>>
>;
export type CheckPolicyMutationBody = CheckPolicyRequest;
export type CheckPolicyMutationError = unknown;

export const useCheckPolicy = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checkPolicy>>,
    TError,
    { data: CheckPolicyRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof checkPolicy>>,
    { data: CheckPolicyRequest }
  > = (props) => {
    const { data } = props ?? {};

    return checkPolicy(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof checkPolicy>>,
    TError,
    { data: CheckPolicyRequest },
    TContext
  >(mutationFn, mutationOptions);
};
export const getProgramExpensePolicy = (
  policyId: string,
  signal?: AbortSignal,
) => {
  return customInstance<Policy>({
    url: `/web/v2/policies/${policyId}`,
    method: 'get',
    signal,
  });
};

export const getGetProgramExpensePolicyQueryKey = (policyId: string) => [
  `/web/v2/policies/${policyId}`,
];

export type GetProgramExpensePolicyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProgramExpensePolicy>>
>;
export type GetProgramExpensePolicyQueryError = unknown;

export const useGetProgramExpensePolicy = <
  TData = Awaited<ReturnType<typeof getProgramExpensePolicy>>,
  TError = unknown,
>(
  policyId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProgramExpensePolicy>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetProgramExpensePolicyQueryKey(policyId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getProgramExpensePolicy>>
  > = ({ signal }) => getProgramExpensePolicy(policyId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getProgramExpensePolicy>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!policyId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const putProgramExpensePolicy = (
  policyId: string,
  upsertPolicyRequest: UpsertPolicyRequest,
) => {
  return customInstance<Policy>({
    url: `/web/v2/policies/${policyId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: upsertPolicyRequest,
  });
};

export type PutProgramExpensePolicyMutationResult = NonNullable<
  Awaited<ReturnType<typeof putProgramExpensePolicy>>
>;
export type PutProgramExpensePolicyMutationBody = UpsertPolicyRequest;
export type PutProgramExpensePolicyMutationError = unknown;

export const usePutProgramExpensePolicy = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProgramExpensePolicy>>,
    TError,
    { policyId: string; data: UpsertPolicyRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putProgramExpensePolicy>>,
    { policyId: string; data: UpsertPolicyRequest }
  > = (props) => {
    const { policyId, data } = props ?? {};

    return putProgramExpensePolicy(policyId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof putProgramExpensePolicy>>,
    TError,
    { policyId: string; data: UpsertPolicyRequest },
    TContext
  >(mutationFn, mutationOptions);
};
export const deleteProgramExpensePolicy = (policyId: string) => {
  return customInstance<void>({
    url: `/web/v2/policies/${policyId}`,
    method: 'delete',
  });
};

export type DeleteProgramExpensePolicyMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteProgramExpensePolicy>>
>;

export type DeleteProgramExpensePolicyMutationError = unknown;

export const useDeleteProgramExpensePolicy = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProgramExpensePolicy>>,
    TError,
    { policyId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteProgramExpensePolicy>>,
    { policyId: string }
  > = (props) => {
    const { policyId } = props ?? {};

    return deleteProgramExpensePolicy(policyId);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteProgramExpensePolicy>>,
    TError,
    { policyId: string },
    TContext
  >(mutationFn, mutationOptions);
};
