/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { QueryServiceIdParameter } from './component-parameters/queryServiceIdParameter';
import type { QueryRefundsEnabledParameter } from './component-parameters/queryRefundsEnabledParameter';

export type MembershipProgramsGetParams = {
  service_id?: QueryServiceIdParameter;
  refunds_enabled?: QueryRefundsEnabledParameter;
};
