/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  Card,
  CardUpdateRequest,
  ConvertCardToPhysicalRequest,
  CardImage,
  CardUpdateStatusRequest,
  CardsGetResponse,
  CardsGetParams,
} from '../../models';
import type {
  RetrievePinResponse,
  RetrievePinRequest,
} from '../../models/component-auth';
import { customInstance } from '../../config';

export const cardGet = (cardId: string, signal?: AbortSignal) => {
  return customInstance<Card>({
    url: `/web/v2/cards/${cardId}`,
    method: 'get',
    signal,
  });
};

export const getCardGetQueryKey = (cardId: string) => [
  `/web/v2/cards/${cardId}`,
];

export type CardGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof cardGet>>
>;
export type CardGetQueryError = unknown;

export const useCardGet = <
  TData = Awaited<ReturnType<typeof cardGet>>,
  TError = unknown,
>(
  cardId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof cardGet>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCardGetQueryKey(cardId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof cardGet>>> = ({
    signal,
  }) => cardGet(cardId, signal);

  const query = useQuery<Awaited<ReturnType<typeof cardGet>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!cardId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Allows operators to change the restriction group (MID or MCC) applied to a card

 */
export const cardUpdate = (
  cardId: string,
  cardUpdateRequest: CardUpdateRequest,
) => {
  return customInstance<Card>({
    url: `/web/v2/cards/${cardId}`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: cardUpdateRequest,
  });
};

export type CardUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof cardUpdate>>
>;
export type CardUpdateMutationBody = CardUpdateRequest;
export type CardUpdateMutationError = unknown;

export const useCardUpdate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cardUpdate>>,
    TError,
    { cardId: string; data: CardUpdateRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cardUpdate>>,
    { cardId: string; data: CardUpdateRequest }
  > = (props) => {
    const { cardId, data } = props ?? {};

    return cardUpdate(cardId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof cardUpdate>>,
    TError,
    { cardId: string; data: CardUpdateRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Set PIN code of virtual card and request a conversion to a physical card.
The PIN should adhere to following rules:
  - Should contain exactly 4 digits
  - No palindrome (e.g. 1001)
  - No sequence (e.g. 1234 or 7654)

 */
export const cardConvertToPhysical = (
  cardId: string,
  convertCardToPhysicalRequest: ConvertCardToPhysicalRequest,
) => {
  return customInstance<Card>({
    url: `/web/v2/cards/${cardId}/convert_to_physical`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: convertCardToPhysicalRequest,
  });
};

export type CardConvertToPhysicalMutationResult = NonNullable<
  Awaited<ReturnType<typeof cardConvertToPhysical>>
>;
export type CardConvertToPhysicalMutationBody = ConvertCardToPhysicalRequest;
export type CardConvertToPhysicalMutationError = unknown;

export const useCardConvertToPhysical = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cardConvertToPhysical>>,
    TError,
    { cardId: string; data: ConvertCardToPhysicalRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cardConvertToPhysical>>,
    { cardId: string; data: ConvertCardToPhysicalRequest }
  > = (props) => {
    const { cardId, data } = props ?? {};

    return cardConvertToPhysical(cardId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof cardConvertToPhysical>>,
    TError,
    { cardId: string; data: ConvertCardToPhysicalRequest },
    TContext
  >(mutationFn, mutationOptions);
};
export const cardImageGet = (cardId: string, signal?: AbortSignal) => {
  return customInstance<CardImage>({
    url: `/web/v2/cards/${cardId}/image`,
    method: 'get',
    signal,
  });
};

export const getCardImageGetQueryKey = (cardId: string) => [
  `/web/v2/cards/${cardId}/image`,
];

export type CardImageGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof cardImageGet>>
>;
export type CardImageGetQueryError = unknown;

export const useCardImageGet = <
  TData = Awaited<ReturnType<typeof cardImageGet>>,
  TError = unknown,
>(
  cardId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof cardImageGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCardImageGetQueryKey(cardId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof cardImageGet>>> = ({
    signal,
  }) => cardImageGet(cardId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof cardImageGet>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!cardId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * This path is rate limited to 10 calls per minute based on the user ID.

 */
export const cardPinRetrieval = (
  cardId: string,
  retrievePinRequest: RetrievePinRequest,
) => {
  return customInstance<RetrievePinResponse>({
    url: `/web/v2/cards/${cardId}/pin_retrieval`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: retrievePinRequest,
  });
};

export type CardPinRetrievalMutationResult = NonNullable<
  Awaited<ReturnType<typeof cardPinRetrieval>>
>;
export type CardPinRetrievalMutationBody = RetrievePinRequest;
export type CardPinRetrievalMutationError = unknown;

export const useCardPinRetrieval = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cardPinRetrieval>>,
    TError,
    { cardId: string; data: RetrievePinRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cardPinRetrieval>>,
    { cardId: string; data: RetrievePinRequest }
  > = (props) => {
    const { cardId, data } = props ?? {};

    return cardPinRetrieval(cardId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof cardPinRetrieval>>,
    TError,
    { cardId: string; data: RetrievePinRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Allows operators to override card status (e.g unlock card if user blocked it accidently)

 */
export const cardUpdateStatus = (
  cardId: string,
  cardUpdateStatusRequest: CardUpdateStatusRequest,
) => {
  return customInstance<Card>({
    url: `/web/v2/cards/${cardId}/card_status`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: cardUpdateStatusRequest,
  });
};

export type CardUpdateStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof cardUpdateStatus>>
>;
export type CardUpdateStatusMutationBody = CardUpdateStatusRequest;
export type CardUpdateStatusMutationError = unknown;

export const useCardUpdateStatus = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cardUpdateStatus>>,
    TError,
    { cardId: string; data: CardUpdateStatusRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cardUpdateStatus>>,
    { cardId: string; data: CardUpdateStatusRequest }
  > = (props) => {
    const { cardId, data } = props ?? {};

    return cardUpdateStatus(cardId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof cardUpdateStatus>>,
    TError,
    { cardId: string; data: CardUpdateStatusRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Unlock the card when it was blocked because of too many wrong pin entered by the user

 */
export const cardUnblockPin = (cardId: string) => {
  return customInstance<Card>({
    url: `/web/v2/cards/${cardId}/unblock_pin`,
    method: 'post',
  });
};

export type CardUnblockPinMutationResult = NonNullable<
  Awaited<ReturnType<typeof cardUnblockPin>>
>;

export type CardUnblockPinMutationError = unknown;

export const useCardUnblockPin = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cardUnblockPin>>,
    TError,
    { cardId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cardUnblockPin>>,
    { cardId: string }
  > = (props) => {
    const { cardId } = props ?? {};

    return cardUnblockPin(cardId);
  };

  return useMutation<
    Awaited<ReturnType<typeof cardUnblockPin>>,
    TError,
    { cardId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const cardsGet = (params?: CardsGetParams, signal?: AbortSignal) => {
  return customInstance<CardsGetResponse>({
    url: `/web/v2/cards`,
    method: 'get',
    params,
    signal,
  });
};

export const getCardsGetQueryKey = (params?: CardsGetParams) => [
  `/web/v2/cards`,
  ...(params ? [params] : []),
];

export type CardsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof cardsGet>>
>;
export type CardsGetQueryError = unknown;

export const useCardsGet = <
  TData = Awaited<ReturnType<typeof cardsGet>>,
  TError = unknown,
>(
  params?: CardsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof cardsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCardsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof cardsGet>>> = ({
    signal,
  }) => cardsGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof cardsGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
