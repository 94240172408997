/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Registration API
 * Registration
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  OrganisationsGetResponse,
  ErrorResponse,
  OrganisationsGetParams,
  Organisation,
  OrganisationCreateBody,
  OrganisationUpdateBody,
  OrganisationRefusalReasonGetParams,
  OrganisationRefusalReasonRequestCreate,
  OrganisationsStatisticsGetResponse,
  OrganisationsStatisticsGetParams,
} from '../../models';
import { customInstance } from '../../config';

export const organisationsGet = (
  params?: OrganisationsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<OrganisationsGetResponse>({
    url: `/web/organisations`,
    method: 'get',
    params,
    signal,
  });
};

export const getOrganisationsGetQueryKey = (
  params?: OrganisationsGetParams,
) => [`/web/organisations`, ...(params ? [params] : [])];

export type OrganisationsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof organisationsGet>>
>;
export type OrganisationsGetQueryError = ErrorResponse | void;

export const useOrganisationsGet = <
  TData = Awaited<ReturnType<typeof organisationsGet>>,
  TError = ErrorResponse | void,
>(
  params?: OrganisationsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof organisationsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOrganisationsGetQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof organisationsGet>>
  > = ({ signal }) => organisationsGet(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof organisationsGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const organisationCreate = (
  organisationCreateBody: OrganisationCreateBody,
) => {
  return customInstance<Organisation>({
    url: `/web/organisations`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: organisationCreateBody,
  });
};

export type OrganisationCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof organisationCreate>>
>;
export type OrganisationCreateMutationBody = OrganisationCreateBody;
export type OrganisationCreateMutationError = unknown;

export const useOrganisationCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organisationCreate>>,
    TError,
    { data: OrganisationCreateBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organisationCreate>>,
    { data: OrganisationCreateBody }
  > = (props) => {
    const { data } = props ?? {};

    return organisationCreate(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof organisationCreate>>,
    TError,
    { data: OrganisationCreateBody },
    TContext
  >(mutationFn, mutationOptions);
};
export const organisationGet = (
  organisationId: string,
  signal?: AbortSignal,
) => {
  return customInstance<Organisation>({
    url: `/web/organisations/${organisationId}`,
    method: 'get',
    signal,
  });
};

export const getOrganisationGetQueryKey = (organisationId: string) => [
  `/web/organisations/${organisationId}`,
];

export type OrganisationGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof organisationGet>>
>;
export type OrganisationGetQueryError = unknown;

export const useOrganisationGet = <
  TData = Awaited<ReturnType<typeof organisationGet>>,
  TError = unknown,
>(
  organisationId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof organisationGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOrganisationGetQueryKey(organisationId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organisationGet>>> = ({
    signal,
  }) => organisationGet(organisationId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof organisationGet>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!organisationId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const organisationUpdate = (
  organisationId: string,
  organisationUpdateBody: OrganisationUpdateBody,
) => {
  return customInstance<Organisation>({
    url: `/web/organisations/${organisationId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: organisationUpdateBody,
  });
};

export type OrganisationUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof organisationUpdate>>
>;
export type OrganisationUpdateMutationBody = OrganisationUpdateBody;
export type OrganisationUpdateMutationError = unknown;

export const useOrganisationUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organisationUpdate>>,
    TError,
    { organisationId: string; data: OrganisationUpdateBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organisationUpdate>>,
    { organisationId: string; data: OrganisationUpdateBody }
  > = (props) => {
    const { organisationId, data } = props ?? {};

    return organisationUpdate(organisationId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof organisationUpdate>>,
    TError,
    { organisationId: string; data: OrganisationUpdateBody },
    TContext
  >(mutationFn, mutationOptions);
};
export const organisationDelete = (organisationId: string) => {
  return customInstance<void>({
    url: `/web/organisations/${organisationId}`,
    method: 'delete',
  });
};

export type OrganisationDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof organisationDelete>>
>;

export type OrganisationDeleteMutationError = unknown;

export const useOrganisationDelete = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organisationDelete>>,
    TError,
    { organisationId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organisationDelete>>,
    { organisationId: string }
  > = (props) => {
    const { organisationId } = props ?? {};

    return organisationDelete(organisationId);
  };

  return useMutation<
    Awaited<ReturnType<typeof organisationDelete>>,
    TError,
    { organisationId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const organisationBankReview = (organisationId: string) => {
  return customInstance<Organisation>({
    url: `/web/organisations/${organisationId}/bank-review`,
    method: 'put',
  });
};

export type OrganisationBankReviewMutationResult = NonNullable<
  Awaited<ReturnType<typeof organisationBankReview>>
>;

export type OrganisationBankReviewMutationError = unknown;

export const useOrganisationBankReview = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organisationBankReview>>,
    TError,
    { organisationId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organisationBankReview>>,
    { organisationId: string }
  > = (props) => {
    const { organisationId } = props ?? {};

    return organisationBankReview(organisationId);
  };

  return useMutation<
    Awaited<ReturnType<typeof organisationBankReview>>,
    TError,
    { organisationId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const organisationInternalReview = (organisationId: string) => {
  return customInstance<Organisation>({
    url: `/web/organisations/${organisationId}/internal-review`,
    method: 'put',
  });
};

export type OrganisationInternalReviewMutationResult = NonNullable<
  Awaited<ReturnType<typeof organisationInternalReview>>
>;

export type OrganisationInternalReviewMutationError = unknown;

export const useOrganisationInternalReview = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organisationInternalReview>>,
    TError,
    { organisationId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organisationInternalReview>>,
    { organisationId: string }
  > = (props) => {
    const { organisationId } = props ?? {};

    return organisationInternalReview(organisationId);
  };

  return useMutation<
    Awaited<ReturnType<typeof organisationInternalReview>>,
    TError,
    { organisationId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const organisationRefusalReasonGet = (
  organisationId: string,
  params?: OrganisationRefusalReasonGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<OrganisationsGetResponse>({
    url: `/web/organisations/${organisationId}/refusal-reasons`,
    method: 'get',
    params,
    signal,
  });
};

export const getOrganisationRefusalReasonGetQueryKey = (
  organisationId: string,
  params?: OrganisationRefusalReasonGetParams,
) => [
  `/web/organisations/${organisationId}/refusal-reasons`,
  ...(params ? [params] : []),
];

export type OrganisationRefusalReasonGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof organisationRefusalReasonGet>>
>;
export type OrganisationRefusalReasonGetQueryError = unknown;

export const useOrganisationRefusalReasonGet = <
  TData = Awaited<ReturnType<typeof organisationRefusalReasonGet>>,
  TError = unknown,
>(
  organisationId: string,
  params?: OrganisationRefusalReasonGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof organisationRefusalReasonGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getOrganisationRefusalReasonGetQueryKey(organisationId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof organisationRefusalReasonGet>>
  > = ({ signal }) =>
    organisationRefusalReasonGet(organisationId, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof organisationRefusalReasonGet>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!organisationId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const organisationRefusalReasonPost = (
  organisationId: string,
  organisationRefusalReasonRequestCreate: OrganisationRefusalReasonRequestCreate,
) => {
  return customInstance<void>({
    url: `/web/organisations/${organisationId}/refusal-reasons`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: organisationRefusalReasonRequestCreate,
  });
};

export type OrganisationRefusalReasonPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof organisationRefusalReasonPost>>
>;
export type OrganisationRefusalReasonPostMutationBody =
  OrganisationRefusalReasonRequestCreate;
export type OrganisationRefusalReasonPostMutationError = unknown;

export const useOrganisationRefusalReasonPost = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organisationRefusalReasonPost>>,
    TError,
    { organisationId: string; data: OrganisationRefusalReasonRequestCreate },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organisationRefusalReasonPost>>,
    { organisationId: string; data: OrganisationRefusalReasonRequestCreate }
  > = (props) => {
    const { organisationId, data } = props ?? {};

    return organisationRefusalReasonPost(organisationId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof organisationRefusalReasonPost>>,
    TError,
    { organisationId: string; data: OrganisationRefusalReasonRequestCreate },
    TContext
  >(mutationFn, mutationOptions);
};
export const organisationsStatisticsGet = (
  params?: OrganisationsStatisticsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<OrganisationsStatisticsGetResponse>({
    url: `/web/organisations/statistics`,
    method: 'get',
    params,
    signal,
  });
};

export const getOrganisationsStatisticsGetQueryKey = (
  params?: OrganisationsStatisticsGetParams,
) => [`/web/organisations/statistics`, ...(params ? [params] : [])];

export type OrganisationsStatisticsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof organisationsStatisticsGet>>
>;
export type OrganisationsStatisticsGetQueryError = ErrorResponse | void;

export const useOrganisationsStatisticsGet = <
  TData = Awaited<ReturnType<typeof organisationsStatisticsGet>>,
  TError = ErrorResponse | void,
>(
  params?: OrganisationsStatisticsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof organisationsStatisticsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOrganisationsStatisticsGetQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof organisationsStatisticsGet>>
  > = ({ signal }) => organisationsStatisticsGet(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof organisationsStatisticsGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};
