/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type UserLanguage = typeof UserLanguage[keyof typeof UserLanguage];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserLanguage = {
  fr: 'fr',
  nl: 'nl',
  en: 'en',
} as const;
