/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

/**
 * CREDIT_CARD: A credit card. E.g. Visa or Mastercard
 */
export type QueryPaymentMethodTypeParameter =
  typeof QueryPaymentMethodTypeParameter[keyof typeof QueryPaymentMethodTypeParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QueryPaymentMethodTypeParameter = {
  CREDIT_CARD: 'CREDIT_CARD',
} as const;
