/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Url } from './component-common/url';

export type NotionVideoExternal = {
  url?: Url;
};
