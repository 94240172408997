/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { SocialSecretaryDataSocialSecretaryName } from './socialSecretaryDataSocialSecretaryName';
import type { SocialSecretaryDataSocialSecretaryEmployeeType } from './socialSecretaryDataSocialSecretaryEmployeeType';

export interface SocialSecretaryData {
  social_secretary_name?: SocialSecretaryDataSocialSecretaryName;
  social_secretary_employee_type?: SocialSecretaryDataSocialSecretaryEmployeeType;
  social_secretary_employee_reference?: string;
}
