/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Expense } from './expense';
import type { ExpenseWithMetaAllOf } from './expenseWithMetaAllOf';

export type ExpenseWithMeta = Expense & ExpenseWithMetaAllOf;
