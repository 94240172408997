/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

/**
 * used as filter to fetch merchants not assigned yet to a service/provider
 */
export type QueryUnassignedParameter = boolean;
