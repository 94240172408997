/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Provider } from './provider';
import type { Service } from './service';

export interface Merchant {
  /** Specific attributes : mcc  */
  readonly mcc?: string;
  readonly merchant_id: string;
  merchant_name: string;
  merchant_category_code?: string;
  provider?: Provider;
  service?: Service;
}
