/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { QueryOnlyUnsignedParameter } from './component-parameters/queryOnlyUnsignedParameter';
import type { QueryOnlyRequiredParameter } from './component-parameters/queryOnlyRequiredParameter';

export type MembershipSwornStatementsGetParams = {
  only_unsigned?: QueryOnlyUnsignedParameter;
  only_required?: QueryOnlyRequiredParameter;
};
