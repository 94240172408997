/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type { ProviderListResponse, ListProvidersParams } from '../../models';
import { customInstance } from '../../config';

export const listProviders = (
  params?: ListProvidersParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProviderListResponse>({
    url: `/web/v2/providers`,
    method: 'get',
    params,
    signal,
  });
};

export const getListProvidersQueryKey = (params?: ListProvidersParams) => [
  `/web/v2/providers`,
  ...(params ? [params] : []),
];

export type ListProvidersQueryResult = NonNullable<
  Awaited<ReturnType<typeof listProviders>>
>;
export type ListProvidersQueryError = unknown;

export const useListProviders = <
  TData = Awaited<ReturnType<typeof listProviders>>,
  TError = unknown,
>(
  params?: ListProvidersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listProviders>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListProvidersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listProviders>>> = ({
    signal,
  }) => listProviders(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof listProviders>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};
