/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type JourneyTripType =
  typeof JourneyTripType[keyof typeof JourneyTripType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JourneyTripType = {
  COMPLETE: 'COMPLETE',
  PARTIAL: 'PARTIAL',
} as const;
