/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { ExpenseStatus } from './expenseStatus';
import type { ExpenseRefundStatus } from './expenseRefundStatus';
import type { ExpenseRefundIssuer } from './expenseRefundIssuer';

export interface ExpenseExportFilter {
  review_status?: ExpenseStatus;
  refund_status?: ExpenseRefundStatus;
  refund_issuer?: ExpenseRefundIssuer;
  employee_name?: string;
  date_from?: string;
  date_to?: string;
  expense_ids?: string[];
  only_managed_teams?: boolean;
}
