/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Error } from './component-common/error';

/**
 * invalid limit payload
 */
export type Error400LimitInvalidPayloadResponse = Error;
