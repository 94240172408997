/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { WalletStatus } from './walletStatus';

export interface Wallet {
  current_amount: number;
  status: WalletStatus;
  iban_number: string;
  bic: string;
  wallet_reference: string;
}
