/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { ExportFormat } from './exportFormat';

export interface ExpenseExportResponse {
  total_items: number;
  format: ExportFormat;
  file_url: string;
}
