/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export interface PaymentMethods {
  /** Service or product is available in the app. */
  app: boolean;
  /** Service or product is available using the card. */
  card: boolean;
  /** Service or product is available when manually creating a refund. */
  refund: boolean;
}
