/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { PaymentMethodType } from './paymentMethodType';
import type { CreatePaymentMethodCreditCardRequest } from './createPaymentMethodCreditCardRequest';

/**
 * The field which matches the type is required. E.g. on type "CREDIT_CARD", the field "credit_card" is required.

 */
export interface CreatePaymentMethodRequest {
  user_id: string;
  payment_method_type: PaymentMethodType;
  credit_card?: CreatePaymentMethodCreditCardRequest;
}
