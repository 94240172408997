/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Invitation } from './invitation';
import type { RegistrationWithMeta } from './registrationWithMeta';

export interface InvitationWithRegistration {
  invitation?: Invitation;
  registration?: RegistrationWithMeta;
}
