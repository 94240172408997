/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

/**
 * DRAFT: user has created his expense but did not submit it yet PENDING: User has submitted the expense, can't edit anymore. The Manager can review it NEED_MORE_INFO: Manager has review the expense but info are missing. Employee can add justifications REFUSED: Expense has been refused by manager. Final state APPROVDED: Manager has approve the expense. Final state CANCELED: Expense has been canceled by user for manual expense (pending expense that contains a mistake). Final state MISSING_PROVIDER: Nobody but skipr team can touch this expense. Technical status because a expense has been made from the card and the provider is unkonwn
 */
export type ExpenseStatus = typeof ExpenseStatus[keyof typeof ExpenseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseStatus = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  NEED_MORE_INFO: 'NEED_MORE_INFO',
  REFUSED: 'REFUSED',
  APPROVED: 'APPROVED',
  CANCELED: 'CANCELED',
  MISSING_PROVIDER: 'MISSING_PROVIDER',
} as const;
