/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { QueryUserIdParameter } from './component-parameters/queryUserIdParameter';
import type { QueryPaymentMethodTypeParameter } from './queryPaymentMethodTypeParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';

export type ListPaymentMethodParams = {
  user_id?: QueryUserIdParameter;
  payment_method_type?: QueryPaymentMethodTypeParameter;
  page_number?: QueryPageNumberParameter;
  page_size?: QueryPageSizeParameter;
};
