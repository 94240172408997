/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { MembershipSummaryTopup } from './membershipSummaryTopup';
import type { Meta } from './meta';

export interface MembershipSummaryTopupListResponse {
  data?: MembershipSummaryTopup[];
  meta?: Meta;
}
