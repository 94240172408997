/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Service } from './service';
import type { ProgramServiceAllOf } from './programServiceAllOf';

export type ProgramService = Service & ProgramServiceAllOf;
