/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { ActivationStatus } from './activationStatus';
import type { SwornStatement } from './swornStatement';
import type { SpendingPolicy } from './spendingPolicy';

export type ProgramAllOf = {
  readonly id?: string;
  readonly organisation_id?: string;
  name?: string;
  name_key?: string;
  start_date?: string;
  readonly status?: ActivationStatus;
  /** Readonly field to indicate budget period start date */
  readonly current_budget_period_start?: string;
  /** Readonly field to indicate budget period end date */
  readonly current_budget_period_end?: string;
  /** Editable field to define budget period */
  budget_period_start_month?: number;
  sworn_statement?: SwornStatement;
  readonly template_id?: string;
  use_recommended_spending_policy?: boolean;
  recommended_spending_policy?: SpendingPolicy;
};
