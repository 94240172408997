import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useTheme,
} from '@chakra-ui/react';
import {
  getOrganisationGetQueryKey,
  useOrganisationBankReview,
  useOrganisationGet,
  useShareholdersGet,
} from '@libs/api/endpoints';
import { KYCStatus, Shareholder, Status } from '@libs/api/models';
import { OrganisationAllOfOrganisationScope } from '@libs/api/models/organisationAllOfOrganisationScope';
import { FORMAT_DATE_WITH_HOURS, QueryStatus } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { capitalize } from '@libs/core/utils';
import {
  addQueryParams,
  getPath,
  ROUTES,
  Routes,
} from '@libs/dashboard-core/routes';
import { diffOfDaysFromToday } from '@libs/dashboard-core/utils/date';
import { StatusToTagStatus } from '@libs/dashboard-core/utils/status';
import { Loader, PageHeader, Tag, Tooltip } from '@libs/ui/components';
import { Link } from '@libs/ui/components/link';
import { useDrawer } from '@libs/ui/components/table/table.utils';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  QueryStatus as QueryStatusType,
  useQueryClient,
} from '@tanstack/react-query';
import {
  Link as RouterLink,
  NavLink,
  useLocation,
  useParams,
} from 'react-router-dom';
import { DashboardLayout } from '../../../components/dashboard-layout';
import { DrawerType } from '../../../dashboard.utils';
import { EmployeesTable } from '../../employee/components/employees-table';
import { GroupsTable } from '../../program/components/groups-table';

import { BankReviewDrawer } from './components/bank-review-drawer';
import { EmployeesUploadDrawer } from './components/employees-upload-drawer';
import { KycTable } from './components/kyc-table';
import { OrganisationDetailsOverview } from './components/organisation-details-overview';
import { SettingsDrawer } from './components/settings-drawer';
import { ProgramsTable } from '../../program/components/programs-table';

export const useOrganisationDetailsPageRoutes = () => {
  return [ROUTES[Routes.DASHBOARD_ORGANISATIONS_DETAILS]];
};

export const OrganisationDetailsPage = () => {
  const [shareholder, setShareholder] = useState<Shareholder>(null);
  const [bankReviewStatus, setBankReviewStatus] = useState<QueryStatusType>(
    QueryStatus.idle,
  );
  const [bankReviewError, setBankReviewError] = useState(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { organisationId } = useParams<{ organisationId: string }>();
  const { isOpen, onClose, drawerHandler, selectedDrawerType } = useDrawer();
  const routes = useOrganisationDetailsPageRoutes();
  const location = useLocation();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    routes
      ?.map((elem, index) => {
        return location.pathname.includes(elem.path) && index;
      })
      .filter((e) => e)[0] || 0,
  );
  const { data: organisation, isLoading } = useOrganisationGet(organisationId);
  const { data: shareholders, isLoading: isLoadingShareholders } =
    useShareholdersGet({
      organisation_id: organisationId,
    });
  const { mutateAsync: mutateBankReview } = useOrganisationBankReview({
    mutation: {
      onSuccess: (data) => {
        setBankReviewStatus(QueryStatus.success);
        drawerHandler();

        // Update organisation data in cache with the response from bank-review
        // This will change the organisation status in the detail page without needing a page refresh
        const queryKey = getOrganisationGetQueryKey(organisationId);
        queryClient.setQueryData(queryKey, data);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        setBankReviewStatus(QueryStatus.error);
        setBankReviewError(error.translation?.key || error.detail);
        drawerHandler();
      },
    },
  });

  const startBankReview = () => {
    setBankReviewStatus(QueryStatus.loading);
    drawerHandler();
    mutateBankReview({ organisationId: organisationId });
  };

  const isKycDisabled =
    organisation?.kyc_status === KYCStatus.PENDING ||
    organisation?.kyc_status === KYCStatus.VALIDATED;

  const tabList = [
    {
      title: t(i18nKeys.common.employees_list),
      component: () => (
        <>
          <Flex
            display={{ base: 'block', sm: 'flex' }}
            justify="space-between"
            mt="6rem"
          >
            <Heading size="Title2">{t(i18nKeys.common.employees)}</Heading>
            <Button
              mt={{ base: '2rem', md: '0' }}
              variant="primary"
              size="body1"
              onClick={() => drawerHandler({ drawerType: DrawerType.create })}
            >
              {t(i18nKeys.common.add_employees)}
            </Button>
          </Flex>
          <EmployeesTable organisation={organisation} />
        </>
      ),
      route: Routes.DASHBOARD_ORGANISATIONS_DETAILS,
    },
    {
      title: t(i18nKeys.operator.organisations.details.title),
      component: () => (
        <>
          <Button
            variant="border"
            size="body2"
            mt="1rem"
            width={{ sm: 'auto', base: '100%' }}
            onClick={() => drawerHandler({ drawerType: DrawerType.edit })}
          >
            {t(i18nKeys.common.settings)}
          </Button>
          <OrganisationDetailsOverview
            organisation={organisation}
            shareholders={shareholders?.data}
          />
          {organisation?.status !== Status.VALIDATED ||
          (shareholder !== null &&
            shareholder?.registration_status !== Status.VALIDATED) ? (
            <>
              <Heading size="Title2" mt="6rem">
                {t(i18nKeys.operator.organisations.details.kyc.title)}
              </Heading>
              <Text>
                {t(i18nKeys.operator.organisations.details.kyc.introduction)}
              </Text>
              <KycTable organisation={organisation} shareholder={shareholder} />
            </>
          ) : null}
        </>
      ),
      route: Routes.DASHBOARD_ORGANISATIONS_DETAILS,
    },
    {
      title: t(i18nKeys.common.programs),
      component: () => (
        <>
          <Flex
            display={{ base: 'block', sm: 'flex' }}
            justify="space-between"
            mt="6rem"
          >
            <Heading size="Title2">{t(i18nKeys.common.programs)}</Heading>
          </Flex>
          <ProgramsTable organisationId={organisation?.id} />
          <Flex
            display={{ base: 'block', sm: 'flex' }}
            justify="space-between"
            mt="6rem"
          >
            <Heading size="Title2">{t(i18nKeys.common.groups.title)}</Heading>
          </Flex>
          <GroupsTable organisation={organisation} />
        </>
      ),
      route: Routes.DASHBOARD_ORGANISATIONS_DETAILS,
    },
    {
      title: t(i18nKeys.common.kyc_status),
      component: () => (
        <>
          <Button
            variant="primary"
            size="body2"
            type="button"
            onClick={() => drawerHandler()}
            width={{ sm: 'auto', base: '100%' }}
            mx="auto"
            mt="1rem"
            mr={{ sm: '1rem', base: '0' }}
            isDisabled={isKycDisabled}
          >
            {t(i18nKeys.onboarding.start_kyc)}
          </Button>
          <Tooltip
            size="lg"
            label={
              <Text size="Small">
                {t(i18nKeys.dashboard.go_onboarding_intro)}
              </Text>
            }
            aria-label={t(i18nKeys.dashboard.go_onboarding_intro)}
          >
            <Button
              as={RouterLink}
              variant="border"
              size="body2"
              mt="1rem"
              width={{ sm: 'auto', base: '100%' }}
              to={getPath(Routes.DASHBOARD_ORGANISATIONS_DETAILS_ONBOARDING, {
                params: { organisationId },
              })}
              mr={{ sm: '1rem', base: '0' }}
            >
              {t(i18nKeys.dashboard.go_onboarding)}
            </Button>
          </Tooltip>
        </>
      ),
      route: Routes.DASHBOARD_ORGANISATIONS_DETAILS,
    },
  ];

  const tabChangeHandler = (index: number) => {
    setActiveTabIndex(index);
  };

  const getUrl = (index: number) =>
    addQueryParams(
      getPath(tabList[index].route, {
        params: { organisationId: organisation?.id },
      }),
      { page: 1, pageSize: 10 },
    );

  useEffect(() => {
    if (shareholders) {
      const shareholder = shareholders.data.find(
        (shareholder) => shareholder.registration_status !== Status.SUGGESTED,
      );
      setShareholder(shareholder);
    }
  }, [shareholders]);

  if (!organisation || isLoading || isLoadingShareholders) {
    return <Loader />;
  }

  const isOldGen =
    organisation?.organisation_scope ===
    OrganisationAllOfOrganisationScope.OLDGEN;

  return (
    <>
      <DashboardLayout>
        <Breadcrumb mb="2rem">
          <BreadcrumbItem>
            <BreadcrumbLink
              variant="breadcrumb"
              as={Link}
              to={getPath(Routes.DASHBOARD)}
            >
              {t(i18nKeys.common.home)}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink
              variant="breadcrumb"
              as={Link}
              to={getPath(Routes.DASHBOARD_ORGANISATIONS)}
            >
              {t(i18nKeys.operator.organisations.title)}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink variant="breadcrumb" as={Link} to="#">
              {capitalize(organisation.legal_name)}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Box>
          <PageHeader
            size="Large"
            title={
              capitalize(organisation.legal_name) +
              (isOldGen ? '(OLD GEN)' : '(NEXT GEN)')
            }
          />
          <Tag status={StatusToTagStatus[organisation.status]} hasIcon>
            <strong>{t(i18nKeys.common.TagStatus[organisation.status])}</strong>
            {organisation.kyc_started_on
              ? ` ${t(i18nKeys.common.from)} ${dayjs(
                  organisation.kyc_started_on,
                ).format(FORMAT_DATE_WITH_HOURS)}
                  ${
                    diffOfDaysFromToday(organisation.kyc_started_on) > 0
                      ? `, ${t(i18nKeys.common.there_is)} ${t(
                          i18nKeys.common.days,
                          {
                            count: diffOfDaysFromToday(
                              organisation.kyc_started_on,
                            ),
                          },
                        )}`
                      : ''
                  }`
              : null}
          </Tag>
        </Box>
        <Tabs
          mt="2rem"
          onChange={tabChangeHandler}
          index={activeTabIndex}
          isLazy
        >
          <TabList>
            {tabList.map(({ title }, index) => (
              <Tab
                textColor="ink.medium"
                fontSize="body2"
                fontFamily={theme.fonts.large}
                _hover={{
                  textColor:
                    activeTabIndex === index
                      ? 'ink.black'
                      : 'main.secondary.normal',
                }}
                _selected={{
                  textColor: 'ink.black',
                  borderBottomColor: 'main.primary.normal',
                }}
                key={title}
                as={NavLink}
                to={getUrl(index)}
              >
                {title}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabList.map(({ title, component }) => (
              <TabPanel key={title}>{component()}</TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </DashboardLayout>
      {(selectedDrawerType === DrawerType.create && (
        <EmployeesUploadDrawer
          organisationId={organisationId}
          isOpen={isOpen}
          onClose={onClose}
        />
      )) ||
        (selectedDrawerType === DrawerType.edit && (
          <SettingsDrawer
            organisation={organisation}
            isOpen={isOpen}
            onClose={onClose}
          />
        )) || (
          <BankReviewDrawer
            isDisabled={isKycDisabled}
            status={bankReviewStatus}
            startBankReview={startBankReview}
            error={bankReviewError}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
    </>
  );
};
