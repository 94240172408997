/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { Service } from './service';

export type PolicyAllOf = {
  readonly id?: Uuid;
  services?: Service[];
};
