/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { ProgramProductConfigurationConfigurationAttributes } from './programProductConfigurationConfigurationAttributes';

export interface ProgramProductConfiguration {
  /** For KmAllowance it will be: amount_per_km, max_km_per_trip and office_address */
  configuration_attributes?: ProgramProductConfigurationConfigurationAttributes;
}
