/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Meta } from './meta';
import type { ProgramServiceGetResponseMetaAllOf } from './programServiceGetResponseMetaAllOf';

export type ProgramServiceGetResponseMeta = Meta &
  ProgramServiceGetResponseMetaAllOf;
