import { Button } from '@chakra-ui/button';
import { Flex, Heading, Text } from '@chakra-ui/layout';
import { Radio } from '@chakra-ui/radio';
import {
  budgetsCreate,
  budgetsGet,
  budgetsUpdate,
  getBudgetsGetQueryKey,
} from '@libs/api/endpoints';
import {
  Budget,
  BudgetsGetParams,
  BudgetStatus,
  BudgetType,
} from '@libs/api/models';
import { formatCurrency } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { validateSchema } from '@libs/core/utils/validation';
import {
  DateField,
  Drawer,
  FormSubmitData,
  handleFormSubmit,
  InputField,
  RadioGroupField,
  SelectField,
  SwitchField,
  useToast,
} from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  EmployeeBudgetCreateFormData,
  EmployeeBudgetUpdateFormData,
} from '../../employee.types';
import { validationEmployeeBudgetsSchema } from '../../employee.validation';
import { Registration } from '@libs/api-v2/models';

type BudgetCreateDrawerProps = {
  budget?: Budget;
  isOpen: boolean;
  onClose: () => void;
  employee?: Registration;
  budgetsGetParams?: BudgetsGetParams;
};

export const BudgetCreateDrawer = ({
  budget,
  isOpen,
  onClose,
  employee,
  budgetsGetParams,
}: BudgetCreateDrawerProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: createBudget, isLoading: isLoadingCreate } = useMutation(
    async ({ values }: FormSubmitData<EmployeeBudgetCreateFormData>) => {
      return budgetsCreate({
        ...values,
        initial_total: values.initial_total * 100,
        membership_id: employee.membership_id,
      });
    },
    {
      onSuccess: () => {
        onClose();
        toast({
          title: t(
            i18nKeys.operator.employees.budgets.add_budget_success.title,
          ),
          content: t(
            i18nKeys.operator.employees.budgets.add_budget_success.content,
          ),
        });
        const queryKey = getBudgetsGetQueryKey(budgetsGetParams);
        queryClient.invalidateQueries(queryKey);
        queryClient.fetchQuery(queryKey, () => budgetsGet(budgetsGetParams));
      },
    },
  );

  const { mutateAsync: updateBudget, isLoading: isLoadingUpdate } = useMutation(
    async ({ values }: FormSubmitData<EmployeeBudgetUpdateFormData>) => {
      return budgetsUpdate(budget.id, {
        ...budget,
        ...values,
        initial_total: values.initial_total * 100,
      });
    },
    {
      onSuccess: () => {
        toast({
          title: t(
            i18nKeys.operator.employees.budgets.edit.budget_success.title,
          ),
          content: t(
            i18nKeys.operator.employees.budgets.edit.budget_success.content,
          ),
        });
        const queryKey = getBudgetsGetQueryKey(budgetsGetParams);
        queryClient.invalidateQueries(queryKey);
        queryClient.fetchQuery(queryKey, () => budgetsGet(budgetsGetParams));
      },
    },
  );

  const initialValues = budget
    ? {
        type: budget.type,
        start_at: budget.start_at,
        end_at: budget.end_at,
        initial_total: Number(formatCurrency({ number: budget.initial_total })),
        is_carry_over_enabled: budget.is_carry_over_enabled,
        status: budget.status,
      }
    : {
        type: BudgetType.MAIN,
        start_at: null,
        end_at: null,
        initial_total: null,
        is_carry_over_enabled: false,
        status: BudgetStatus.VALIDATED,
      };

  const drawerTitle = budget
    ? t(i18nKeys.operator.employees.budgets.edit.title)
    : t(i18nKeys.operator.employees.budgets.create.title, {
        first_name: employee.first_name,
        last_name: employee.last_name,
      });

  const availableBudgetTypes = Object.values(BudgetType).filter(
    (type) => type !== BudgetType.UNCATEGORIZED,
  );

  const submitButtonText = budget
    ? t(i18nKeys.common.submit)
    : t(i18nKeys.common.add);

  return (
    <Drawer title={drawerTitle} isOpen={isOpen} onClose={onClose} size="xl">
      <Formik<EmployeeBudgetCreateFormData | EmployeeBudgetUpdateFormData>
        onSubmit={handleFormSubmit(budget ? updateBudget : createBudget)}
        initialValues={initialValues}
        validate={validateSchema(validationEmployeeBudgetsSchema)}
      >
        {({ isValid, dirty }) => (
          <Form>
            <Drawer.Body>
              {!budget && (
                <Text size="Body1" color="ink.darker">
                  {t(i18nKeys.operator.employees.budgets.create.introduction, {
                    first_name: employee.first_name,
                    last_name: employee.last_name,
                  })}
                </Text>
              )}

              <Flex>
                <Heading size="Title3" mt="2rem">
                  {t(i18nKeys.operator.employees.budgets.type.title)}
                </Heading>
                <Text
                  ml=".2rem"
                  alignSelf="flex-end"
                  color="status.negative.type"
                >
                  *
                </Text>
              </Flex>
              <SelectField name="type" isRequired>
                {Object.values(availableBudgetTypes).map((type) => (
                  <option key={type} value={type}>
                    {t(i18nKeys.operator.employees.budgets.type[type])}
                  </option>
                ))}
              </SelectField>

              <Flex>
                <Heading size="Title3" mt="2rem">
                  {t(i18nKeys.operator.employees.budgets.status.title)}
                </Heading>
                <Text
                  ml=".2rem"
                  alignSelf="flex-end"
                  color="status.negative.type"
                >
                  *
                </Text>
              </Flex>
              <RadioGroupField name="status" isRequired mb="2rem">
                {Object.values(BudgetStatus).map((status) => (
                  <Radio key={status} value={status} mr="3rem">
                    <Text size="Body1" color="ink.dark">
                      {t(i18nKeys.operator.employees.budgets.status[status])}
                    </Text>
                  </Radio>
                ))}
              </RadioGroupField>

              <DateField
                name="start_at"
                label={t(i18nKeys.common.start_at)}
                isRequired
                hasSimpleDate
              />

              <DateField
                mb="2rem"
                name="end_at"
                label={t(i18nKeys.common.end_at)}
                isRequired
                hasSimpleDate
              />

              <InputField
                name="initial_total"
                type="number"
                label={t(i18nKeys.operator.employees.budgets.create.amount)}
                isRequired
              />

              <SwitchField
                mt="2rem"
                name="is_carry_over_enabled"
                label={
                  <Flex h="100%" align="center">
                    <Text size="Body1Bold">
                      {t(
                        i18nKeys.operator.employees.budgets.create
                          .is_carry_over_enabled,
                      )}
                    </Text>
                  </Flex>
                }
              />
            </Drawer.Body>
            <Drawer.Footer>
              <Flex w="100%" justify="space-between">
                <Button
                  size="body2"
                  w="auto"
                  variant="border"
                  onClick={onClose}
                >
                  {t(i18nKeys.common.cancel)}
                </Button>
                <Button
                  type="submit"
                  size="body2"
                  w="auto"
                  variant="primary"
                  disabled={!isValid || !dirty}
                  isLoading={isLoadingCreate || isLoadingUpdate}
                  loadingText={submitButtonText}
                >
                  {submitButtonText}
                </Button>
              </Flex>
            </Drawer.Footer>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};
