import React from 'react';
import { Box } from '@chakra-ui/react';
import { useExpenseGet } from '@libs/api-v2/endpoints/expenses/expenses';
import { Expense } from '@libs/api-v2/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Currency, DescriptionData, Drawer } from '@libs/ui/components';
import { useTranslation } from 'react-i18next';
import { Direction } from '@libs/core/types/direction';
import { FORMAT_DATE_WITH_HOURS } from '@libs/core/constants/date';
import dayjs from 'dayjs';

type ExpenseDetailsDrawerProps = {
  expense: Expense;
  isOpen: boolean;
  onClose: () => void;
};

export const ExpenseDetailsDrawer = ({
  expense,
  isOpen,
  onClose,
}: ExpenseDetailsDrawerProps) => {
  const { t } = useTranslation();
  const { data: expenseDetails, isLoading } = useExpenseGet(expense.id);

  if (isLoading) {
    return null;
  }

  const {
    id,
    amount,
    meta,
    expense_at,
    refunded_at,
    review_status,
    refund_status,
    source,
    refund_issuer,
    amount_refund,
    reviewed_by,
    reviewed_at,
    justification,
    expense_code,
    attachment_url,
    membership_id,
    distance,
    created_at,
  } = expenseDetails;
  const {
    category_name_key,
    program_name,
    product_name_key,
    created_by_first_name,
    created_by_last_name,
    user_first_name,
    user_last_name,
    provider_name,
    service_name_key,
  } = meta;

  return (
    <Drawer
      title={t(i18nKeys.common.expenses)}
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box>
        <DescriptionData direction={Direction.horizontal} title={'id'}>
          {id}
        </DescriptionData>
        <DescriptionData
          direction={Direction.horizontal}
          title={'membership_id'}
        >
          {membership_id}
        </DescriptionData>
        <DescriptionData
          direction={Direction.horizontal}
          title={t(i18nKeys.common.amount)}
        >
          <Currency alignItems="center">{amount.total}</Currency>
        </DescriptionData>
        <DescriptionData
          direction={Direction.horizontal}
          title={t(i18nKeys.operator.expenses.review_status)}
        >
          [{review_status}] on{' '}
          {reviewed_at && dayjs(reviewed_at).format(FORMAT_DATE_WITH_HOURS)} by{' '}
          {reviewed_by}
        </DescriptionData>
        <DescriptionData
          direction={Direction.horizontal}
          title={t(i18nKeys.operator.expenses.refund_status)}
        >
          <Currency alignItems="center">{amount_refund.total}</Currency>
        </DescriptionData>
        <DescriptionData
          direction={Direction.horizontal}
          title={t(i18nKeys.operator.expenses.refund_status)}
        >
          [{refund_status}] on{' '}
          {refunded_at && dayjs(refunded_at).format(FORMAT_DATE_WITH_HOURS)} by{' '}
          {refund_issuer}
        </DescriptionData>
        <DescriptionData
          direction={Direction.horizontal}
          title={t(i18nKeys.operator.expenses.expense_at)}
        >
          {expense_at && dayjs(expense_at).format(FORMAT_DATE_WITH_HOURS)}
        </DescriptionData>
        <DescriptionData
          direction={Direction.horizontal}
          title={t(i18nKeys.operator.expenses.expense_owner)}
        >
          {user_first_name} {user_last_name}
        </DescriptionData>
        <DescriptionData
          direction={Direction.horizontal}
          title={t(i18nKeys.operator.expenses.distance)}
        >
          {distance}
        </DescriptionData>
        <DescriptionData
          direction={Direction.horizontal}
          title={t(i18nKeys.operator.expenses.created_by)}
        >
          {created_by_first_name} {created_by_last_name} (
          {created_at && dayjs(created_at).format(FORMAT_DATE_WITH_HOURS)})
        </DescriptionData>
        <DescriptionData
          direction={Direction.horizontal}
          title={t(i18nKeys.common.transaction)}
        >
          {source} via {expenseDetails.interface}
        </DescriptionData>
        <DescriptionData
          direction={Direction.horizontal}
          title={t(i18nKeys.operator.expenses.program)}
        >
          {program_name}
        </DescriptionData>
        <DescriptionData
          direction={Direction.horizontal}
          title={t(i18nKeys.operator.expenses.service)}
        >
          {service_name_key} ({product_name_key}){' '}
        </DescriptionData>
        <DescriptionData
          direction={Direction.horizontal}
          title={t(i18nKeys.operator.expenses.provider)}
        >
          {provider_name} ({category_name_key})
        </DescriptionData>
        <DescriptionData
          direction={Direction.horizontal}
          title={t(i18nKeys.operator.expenses.justification)}
        >
          {justification}
        </DescriptionData>
        <DescriptionData
          direction={Direction.horizontal}
          title={t(i18nKeys.operator.expenses.expense_code)}
        >
          {expense_code}
        </DescriptionData>
        <DescriptionData
          direction={Direction.horizontal}
          title={t(i18nKeys.operator.expenses.attachment)}
        >
          {attachment_url}
        </DescriptionData>
      </Box>
    </Drawer>
  );
};
