/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type BudgetDistribution =
  typeof BudgetDistribution[keyof typeof BudgetDistribution];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BudgetDistribution = {
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  FULL: 'FULL',
} as const;
