/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { BrowserInfo } from './browserInfo';

export interface CreatePaymentMethodCreditCardRequest {
  /** PSD2 specific data, should be obtained by client via card tokenization API */
  card_holder: string;
  card_token: string;
  /** Should be returned by the provider. E.g. on HiPay, this is called "payment_product". */
  card_type: string;
  /** PSD2 specific data, should be obtained by client via card tokenization API */
  browser_info: BrowserInfo;
  /** After the 3DS flow, the provider redirects to a URL based on the status of the transaction.
The following query parameters will be appended to the base URL:
- payment_method_id: ID of the newly created Payment Method
- skipr_state: State of the payment. Query param "state" is already defined by HiPay. Therefore, parameter needed to be prefixed.
  - ACCEPT: Payment is accepted
  - DECLINE: Payment is declined
  - PENDING: Payment is pending and result is not yet available
  - EXCEPTION: A system error occurred
  - CANCEL: A system error occurred
 */
  redirect_base_url: string;
}
