/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  GroupsGetResponse,
  GroupsGetParams,
  Group,
  GroupCreateRequest,
  GroupUpdateRequest,
  RegistrationsWithoutMetaGetResponse,
  Error404NotFoundResponse,
  ProgramsGetResponse,
} from '../../models';
import type { ErrorResponse, Error } from '../../models/component-common';
import { customInstance } from '../../config';

export const groupsGet = (params?: GroupsGetParams, signal?: AbortSignal) => {
  return customInstance<GroupsGetResponse>({
    url: `/web/v2/groups`,
    method: 'get',
    params,
    signal,
  });
};

export const getGroupsGetQueryKey = (params?: GroupsGetParams) => [
  `/web/v2/groups`,
  ...(params ? [params] : []),
];

export type GroupsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupsGet>>
>;
export type GroupsGetQueryError = ErrorResponse;

export const useGroupsGet = <
  TData = Awaited<ReturnType<typeof groupsGet>>,
  TError = ErrorResponse,
>(
  params?: GroupsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof groupsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsGet>>> = ({
    signal,
  }) => groupsGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof groupsGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const groupsCreate = (groupCreateRequest: GroupCreateRequest) => {
  return customInstance<Group>({
    url: `/web/v2/groups`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: groupCreateRequest,
  });
};

export type GroupsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupsCreate>>
>;
export type GroupsCreateMutationBody = GroupCreateRequest;
export type GroupsCreateMutationError = ErrorResponse;

export const useGroupsCreate = <
  TError = ErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupsCreate>>,
    TError,
    { data: GroupCreateRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupsCreate>>,
    { data: GroupCreateRequest }
  > = (props) => {
    const { data } = props ?? {};

    return groupsCreate(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof groupsCreate>>,
    TError,
    { data: GroupCreateRequest },
    TContext
  >(mutationFn, mutationOptions);
};
export const groupPut = (
  groupId: string,
  groupUpdateRequest: GroupUpdateRequest,
) => {
  return customInstance<Group>({
    url: `/web/v2/groups/${groupId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: groupUpdateRequest,
  });
};

export type GroupPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupPut>>
>;
export type GroupPutMutationBody = GroupUpdateRequest;
export type GroupPutMutationError = Error;

export const useGroupPut = <TError = Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupPut>>,
    TError,
    { groupId: string; data: GroupUpdateRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupPut>>,
    { groupId: string; data: GroupUpdateRequest }
  > = (props) => {
    const { groupId, data } = props ?? {};

    return groupPut(groupId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof groupPut>>,
    TError,
    { groupId: string; data: GroupUpdateRequest },
    TContext
  >(mutationFn, mutationOptions);
};
export const groupDelete = (groupId: string) => {
  return customInstance<void>({
    url: `/web/v2/groups/${groupId}`,
    method: 'delete',
  });
};

export type GroupDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupDelete>>
>;

export type GroupDeleteMutationError = Error;

export const useGroupDelete = <TError = Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupDelete>>,
    TError,
    { groupId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupDelete>>,
    { groupId: string }
  > = (props) => {
    const { groupId } = props ?? {};

    return groupDelete(groupId);
  };

  return useMutation<
    Awaited<ReturnType<typeof groupDelete>>,
    TError,
    { groupId: string },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Fetch all members for a specific group.

 */
export const groupMembersGet = (groupId: string, signal?: AbortSignal) => {
  return customInstance<RegistrationsWithoutMetaGetResponse>({
    url: `/web/v2/groups/${groupId}/members`,
    method: 'get',
    signal,
  });
};

export const getGroupMembersGetQueryKey = (groupId: string) => [
  `/web/v2/groups/${groupId}/members`,
];

export type GroupMembersGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupMembersGet>>
>;
export type GroupMembersGetQueryError = ErrorResponse;

export const useGroupMembersGet = <
  TData = Awaited<ReturnType<typeof groupMembersGet>>,
  TError = ErrorResponse,
>(
  groupId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof groupMembersGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGroupMembersGetQueryKey(groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupMembersGet>>> = ({
    signal,
  }) => groupMembersGet(groupId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof groupMembersGet>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!groupId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const groupMemberPost = (groupId: string, membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/groups/${groupId}/members/${membershipId}`,
    method: 'put',
  });
};

export type GroupMemberPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupMemberPost>>
>;

export type GroupMemberPostMutationError = Error | Error404NotFoundResponse;

export const useGroupMemberPost = <
  TError = Error | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupMemberPost>>,
    TError,
    { groupId: string; membershipId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupMemberPost>>,
    { groupId: string; membershipId: string }
  > = (props) => {
    const { groupId, membershipId } = props ?? {};

    return groupMemberPost(groupId, membershipId);
  };

  return useMutation<
    Awaited<ReturnType<typeof groupMemberPost>>,
    TError,
    { groupId: string; membershipId: string },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Remove member from group.

 */
export const groupMemberDelete = (groupId: string, membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/groups/${groupId}/members/${membershipId}`,
    method: 'delete',
  });
};

export type GroupMemberDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupMemberDelete>>
>;

export type GroupMemberDeleteMutationError = ErrorResponse;

export const useGroupMemberDelete = <
  TError = ErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupMemberDelete>>,
    TError,
    { groupId: string; membershipId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupMemberDelete>>,
    { groupId: string; membershipId: string }
  > = (props) => {
    const { groupId, membershipId } = props ?? {};

    return groupMemberDelete(groupId, membershipId);
  };

  return useMutation<
    Awaited<ReturnType<typeof groupMemberDelete>>,
    TError,
    { groupId: string; membershipId: string },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Fetch all programs applied to a group.

 */
export const groupProgramsGet = (groupId: string, signal?: AbortSignal) => {
  return customInstance<ProgramsGetResponse>({
    url: `/web/v2/groups/${groupId}/programs`,
    method: 'get',
    signal,
  });
};

export const getGroupProgramsGetQueryKey = (groupId: string) => [
  `/web/v2/groups/${groupId}/programs`,
];

export type GroupProgramsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupProgramsGet>>
>;
export type GroupProgramsGetQueryError = ErrorResponse;

export const useGroupProgramsGet = <
  TData = Awaited<ReturnType<typeof groupProgramsGet>>,
  TError = ErrorResponse,
>(
  groupId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof groupProgramsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGroupProgramsGetQueryKey(groupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof groupProgramsGet>>
  > = ({ signal }) => groupProgramsGet(groupId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof groupProgramsGet>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!groupId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
