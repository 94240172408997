/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  ExpensesGetResponse,
  ExpenseListParams,
  ExpenseWithMeta,
  Expense,
  Policy,
  ExpenseValidationRequest,
  ExpenseRefundUpdateRequest,
  ExpenseExportResponse,
  ExpenseExportRequest,
} from '../../models';
import type { Error } from '../../models/component-common';
import { customInstance } from '../../config';

export const expenseList = (
  params?: ExpenseListParams,
  signal?: AbortSignal,
) => {
  return customInstance<ExpensesGetResponse>({
    url: `/web/v2/expenses`,
    method: 'get',
    params,
    signal,
  });
};

export const getExpenseListQueryKey = (params?: ExpenseListParams) => [
  `/web/v2/expenses`,
  ...(params ? [params] : []),
];

export type ExpenseListQueryResult = NonNullable<
  Awaited<ReturnType<typeof expenseList>>
>;
export type ExpenseListQueryError = unknown;

export const useExpenseList = <
  TData = Awaited<ReturnType<typeof expenseList>>,
  TError = unknown,
>(
  params?: ExpenseListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof expenseList>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getExpenseListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof expenseList>>> = ({
    signal,
  }) => expenseList(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof expenseList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const expensesCreate = (expense: Expense) => {
  return customInstance<ExpenseWithMeta>({
    url: `/web/v2/expenses`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: expense,
  });
};

export type ExpensesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof expensesCreate>>
>;
export type ExpensesCreateMutationBody = Expense;
export type ExpensesCreateMutationError = unknown;

export const useExpensesCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesCreate>>,
    TError,
    { data: Expense },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expensesCreate>>,
    { data: Expense }
  > = (props) => {
    const { data } = props ?? {};

    return expensesCreate(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof expensesCreate>>,
    TError,
    { data: Expense },
    TContext
  >(mutationFn, mutationOptions);
};
export const expenseGet = (expenseId: string, signal?: AbortSignal) => {
  return customInstance<ExpenseWithMeta>({
    url: `/web/v2/expenses/${expenseId}`,
    method: 'get',
    signal,
  });
};

export const getExpenseGetQueryKey = (expenseId: string) => [
  `/web/v2/expenses/${expenseId}`,
];

export type ExpenseGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof expenseGet>>
>;
export type ExpenseGetQueryError = unknown;

export const useExpenseGet = <
  TData = Awaited<ReturnType<typeof expenseGet>>,
  TError = unknown,
>(
  expenseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof expenseGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getExpenseGetQueryKey(expenseId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof expenseGet>>> = ({
    signal,
  }) => expenseGet(expenseId, signal);

  const query = useQuery<Awaited<ReturnType<typeof expenseGet>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!expenseId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const expensesUpdate = (expenseId: string, expense: Expense) => {
  return customInstance<ExpenseWithMeta>({
    url: `/web/v2/expenses/${expenseId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: expense,
  });
};

export type ExpensesUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof expensesUpdate>>
>;
export type ExpensesUpdateMutationBody = Expense;
export type ExpensesUpdateMutationError = Error;

export const useExpensesUpdate = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesUpdate>>,
    TError,
    { expenseId: string; data: Expense },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expensesUpdate>>,
    { expenseId: string; data: Expense }
  > = (props) => {
    const { expenseId, data } = props ?? {};

    return expensesUpdate(expenseId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof expensesUpdate>>,
    TError,
    { expenseId: string; data: Expense },
    TContext
  >(mutationFn, mutationOptions);
};
export const expenseGetPolicy = (expenseId: string, signal?: AbortSignal) => {
  return customInstance<Policy | void>({
    url: `/web/v2/expenses/${expenseId}/applied_policy`,
    method: 'get',
    signal,
  });
};

export const getExpenseGetPolicyQueryKey = (expenseId: string) => [
  `/web/v2/expenses/${expenseId}/applied_policy`,
];

export type ExpenseGetPolicyQueryResult = NonNullable<
  Awaited<ReturnType<typeof expenseGetPolicy>>
>;
export type ExpenseGetPolicyQueryError = unknown;

export const useExpenseGetPolicy = <
  TData = Awaited<ReturnType<typeof expenseGetPolicy>>,
  TError = unknown,
>(
  expenseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof expenseGetPolicy>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getExpenseGetPolicyQueryKey(expenseId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof expenseGetPolicy>>
  > = ({ signal }) => expenseGetPolicy(expenseId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof expenseGetPolicy>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!expenseId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const expensesValidationUpdate = (
  expenseId: string,
  expenseValidationRequest: ExpenseValidationRequest,
) => {
  return customInstance<ExpenseWithMeta>({
    url: `/web/v2/expenses/${expenseId}/review`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: expenseValidationRequest,
  });
};

export type ExpensesValidationUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof expensesValidationUpdate>>
>;
export type ExpensesValidationUpdateMutationBody = ExpenseValidationRequest;
export type ExpensesValidationUpdateMutationError = unknown;

export const useExpensesValidationUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesValidationUpdate>>,
    TError,
    { expenseId: string; data: ExpenseValidationRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expensesValidationUpdate>>,
    { expenseId: string; data: ExpenseValidationRequest }
  > = (props) => {
    const { expenseId, data } = props ?? {};

    return expensesValidationUpdate(expenseId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof expensesValidationUpdate>>,
    TError,
    { expenseId: string; data: ExpenseValidationRequest },
    TContext
  >(mutationFn, mutationOptions);
};
export const expensesRefundUpdate = (
  expenseId: string,
  expenseRefundUpdateRequest: ExpenseRefundUpdateRequest,
) => {
  return customInstance<ExpenseWithMeta>({
    url: `/web/v2/expenses/${expenseId}/refund_status`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: expenseRefundUpdateRequest,
  });
};

export type ExpensesRefundUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof expensesRefundUpdate>>
>;
export type ExpensesRefundUpdateMutationBody = ExpenseRefundUpdateRequest;
export type ExpensesRefundUpdateMutationError = unknown;

export const useExpensesRefundUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesRefundUpdate>>,
    TError,
    { expenseId: string; data: ExpenseRefundUpdateRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expensesRefundUpdate>>,
    { expenseId: string; data: ExpenseRefundUpdateRequest }
  > = (props) => {
    const { expenseId, data } = props ?? {};

    return expensesRefundUpdate(expenseId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof expensesRefundUpdate>>,
    TError,
    { expenseId: string; data: ExpenseRefundUpdateRequest },
    TContext
  >(mutationFn, mutationOptions);
};
export const expensesSubmit = (expenseId: string) => {
  return customInstance<ExpenseWithMeta>({
    url: `/web/v2/expenses/${expenseId}/submit`,
    method: 'post',
  });
};

export type ExpensesSubmitMutationResult = NonNullable<
  Awaited<ReturnType<typeof expensesSubmit>>
>;

export type ExpensesSubmitMutationError = unknown;

export const useExpensesSubmit = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesSubmit>>,
    TError,
    { expenseId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expensesSubmit>>,
    { expenseId: string }
  > = (props) => {
    const { expenseId } = props ?? {};

    return expensesSubmit(expenseId);
  };

  return useMutation<
    Awaited<ReturnType<typeof expensesSubmit>>,
    TError,
    { expenseId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const expensesExport = (expenseExportRequest: ExpenseExportRequest) => {
  return customInstance<ExpenseExportResponse>({
    url: `/web/v2/expenses/export`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: expenseExportRequest,
  });
};

export type ExpensesExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof expensesExport>>
>;
export type ExpensesExportMutationBody = ExpenseExportRequest;
export type ExpensesExportMutationError = unknown;

export const useExpensesExport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expensesExport>>,
    TError,
    { data: ExpenseExportRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expensesExport>>,
    { data: ExpenseExportRequest }
  > = (props) => {
    const { data } = props ?? {};

    return expensesExport(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof expensesExport>>,
    TError,
    { data: ExpenseExportRequest },
    TContext
  >(mutationFn, mutationOptions);
};
