/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { BudgetDistribution } from './budgetDistribution';

export interface MembershipSummaryTopup {
  readonly created_on: string;
  readonly amount: number;
  readonly carry_over: boolean;
  budget_distribution: BudgetDistribution;
  /** Program ID of a program topup was made for */
  program_id: string;
}
