/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  SplittableTransactionsResponse,
  ListSplittableTransactionsParams,
  SplittableTransaction,
  Error404NotFoundResponse,
  UpdateSplittableTransactionRequest,
  SplitPayment,
  SplittableTransactionProducts,
  GetEligibleSplittableTransactionProductsParams,
} from '../../models';
import type { Error } from '../../models/component-common';
import { customInstance } from '../../config';

/**
 * Provides a list of outstanding transactions that are potentially splittable
 */
export const listSplittableTransactions = (
  params?: ListSplittableTransactionsParams,
  signal?: AbortSignal,
) => {
  return customInstance<SplittableTransactionsResponse>({
    url: `/web/v2/splittable_transactions`,
    method: 'get',
    params,
    signal,
  });
};

export const getListSplittableTransactionsQueryKey = (
  params?: ListSplittableTransactionsParams,
) => [`/web/v2/splittable_transactions`, ...(params ? [params] : [])];

export type ListSplittableTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listSplittableTransactions>>
>;
export type ListSplittableTransactionsQueryError = unknown;

export const useListSplittableTransactions = <
  TData = Awaited<ReturnType<typeof listSplittableTransactions>>,
  TError = unknown,
>(
  params?: ListSplittableTransactionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listSplittableTransactions>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListSplittableTransactionsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listSplittableTransactions>>
  > = ({ signal }) => listSplittableTransactions(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof listSplittableTransactions>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Allows to provide additional info about splittable transaction ie. service transaction is related to
 */
export const updateSplittableTransaction = (
  splittableTransactionId: string,
  updateSplittableTransactionRequest: UpdateSplittableTransactionRequest,
) => {
  return customInstance<SplittableTransaction>({
    url: `/web/v2/splittable_transactions/${splittableTransactionId}`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: updateSplittableTransactionRequest,
  });
};

export type UpdateSplittableTransactionMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSplittableTransaction>>
>;
export type UpdateSplittableTransactionMutationBody =
  UpdateSplittableTransactionRequest;
export type UpdateSplittableTransactionMutationError =
  | Error
  | Error404NotFoundResponse;

export const useUpdateSplittableTransaction = <
  TError = Error | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSplittableTransaction>>,
    TError,
    {
      splittableTransactionId: string;
      data: UpdateSplittableTransactionRequest;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSplittableTransaction>>,
    {
      splittableTransactionId: string;
      data: UpdateSplittableTransactionRequest;
    }
  > = (props) => {
    const { splittableTransactionId, data } = props ?? {};

    return updateSplittableTransaction(splittableTransactionId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateSplittableTransaction>>,
    TError,
    {
      splittableTransactionId: string;
      data: UpdateSplittableTransactionRequest;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * initiate customer part payment process
 */
export const splittableTransactionEmployeePartPayment = (
  splittableTransactionId: string,
) => {
  return customInstance<SplitPayment>({
    url: `/web/v2/splittable_transactions/${splittableTransactionId}/employee_payment`,
    method: 'post',
  });
};

export type SplittableTransactionEmployeePartPaymentMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof splittableTransactionEmployeePartPayment>>
  >;

export type SplittableTransactionEmployeePartPaymentMutationError =
  | Error404NotFoundResponse
  | Error;

export const useSplittableTransactionEmployeePartPayment = <
  TError = Error404NotFoundResponse | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof splittableTransactionEmployeePartPayment>>,
    TError,
    { splittableTransactionId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof splittableTransactionEmployeePartPayment>>,
    { splittableTransactionId: string }
  > = (props) => {
    const { splittableTransactionId } = props ?? {};

    return splittableTransactionEmployeePartPayment(splittableTransactionId);
  };

  return useMutation<
    Awaited<ReturnType<typeof splittableTransactionEmployeePartPayment>>,
    TError,
    { splittableTransactionId: string },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * retrieve products suitable for splittable transaction assigment
 */
export const getEligibleSplittableTransactionProducts = (
  params?: GetEligibleSplittableTransactionProductsParams,
  signal?: AbortSignal,
) => {
  return customInstance<SplittableTransactionProducts>({
    url: `/web/v2/splittable_transactions/eligible_products`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetEligibleSplittableTransactionProductsQueryKey = (
  params?: GetEligibleSplittableTransactionProductsParams,
) => [
  `/web/v2/splittable_transactions/eligible_products`,
  ...(params ? [params] : []),
];

export type GetEligibleSplittableTransactionProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEligibleSplittableTransactionProducts>>
>;
export type GetEligibleSplittableTransactionProductsQueryError =
  Error404NotFoundResponse;

export const useGetEligibleSplittableTransactionProducts = <
  TData = Awaited<ReturnType<typeof getEligibleSplittableTransactionProducts>>,
  TError = Error404NotFoundResponse,
>(
  params?: GetEligibleSplittableTransactionProductsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getEligibleSplittableTransactionProducts>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetEligibleSplittableTransactionProductsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getEligibleSplittableTransactionProducts>>
  > = ({ signal }) => getEligibleSplittableTransactionProducts(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getEligibleSplittableTransactionProducts>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};
