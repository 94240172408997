/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { ProviderName } from './providerName';
import type { ProviderNameKey } from './providerNameKey';
import type { Url } from './component-common/url';

export interface Provider {
  id?: Uuid;
  name?: ProviderName;
  name_key?: ProviderNameKey;
  icon_url?: Url;
}
