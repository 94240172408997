/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { JourneyTripType } from './journeyTripType';
import type { JourneyType } from './journeyType';
import type { Address } from './address';

export type CreateJourneyRequestConfiguration = {
  trip_type: JourneyTripType;
  type: JourneyType;
  home_address: Address;
  office_address: Address;
  start_station_address?: Address;
  end_station_address?: Address;
  /** The total of km used only for business travel E.g. if you drove 30km in one week (week-end included), you used only 20km for business travel */
  professional_distance?: number;
};
