/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { TripType } from './tripType';
import type { Address } from './address';

export type CostEstimationRequestConfiguration = {
  trip_type_to?: TripType;
  trip_type_back?: TripType;
  home_address?: Address;
  office_address?: Address;
  start_station_address?: Address;
  end_station_address?: Address;
};
