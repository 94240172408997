/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type SocialSecretaryDataSocialSecretaryEmployeeType =
  typeof SocialSecretaryDataSocialSecretaryEmployeeType[keyof typeof SocialSecretaryDataSocialSecretaryEmployeeType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocialSecretaryDataSocialSecretaryEmployeeType = {
  WORKER: 'WORKER',
  CLERK: 'CLERK',
} as const;
