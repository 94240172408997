/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ProgramGroupDataAllOf = {
  is_monthly_limit_enabled?: boolean;
  is_refund_enabled?: boolean;
  is_auto_approved_subscription?: boolean;
  is_auto_approved_expense?: boolean;
  is_auto_approved_refund?: boolean;
};
