import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useTheme,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { useOrganisationGet } from '@libs/api/endpoints';
import { OrganisationAllOfOrganisationScope } from '@libs/api/models/organisationAllOfOrganisationScope';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { capitalize } from '@libs/core/utils';
import {
  getPath,
  Routes,
  ROUTES,
  addQueryParams,
} from '@libs/dashboard-core/routes';
import { Loader } from '@libs/ui/components';
import { Link } from '@libs/ui/components/link';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, NavLink, useLocation } from 'react-router-dom';
import { DashboardLayout } from '../../../components/dashboard-layout';
import { EmployeeDetailsBudgets } from '@libs/dashboard-core/pages/dashboard/operator/employee/details/components/employee-details-budgets';
import { EmployeeDetailsCards } from '@libs/dashboard-core/pages/dashboard/operator/employee/details/components/employee-details-cards';
import { EmployeeDetailsExpenses } from '@libs/dashboard-core/pages/dashboard/operator/employee/details/components/employee-details-expenses';
import { EmployeeDetailsOverview } from '@libs/dashboard-core/pages/dashboard/operator/employee/details/components/employee-details-overview';
import { EmployeeDetailsPrograms } from '@libs/dashboard-core/pages/dashboard/operator/employee/details/components/employee-details-programs';
import React from 'react';
import { useMembershipRegistrationGet } from '@libs/api-v2/endpoints';

export const useEmployeeDetailsPageRoutes = () => {
  return [
    ROUTES[Routes.DASHBOARD_EMPLOYEES_DETAILS_BUDGETS],
    ROUTES[Routes.DASHBOARD_EMPLOYEES_DETAILS_EXPENSES],
    ROUTES[Routes.DASHBOARD_EMPLOYEES_DETAILS_CARD],
    ROUTES[Routes.DASHBOARD_EMPLOYEES_DETAILS_PROGRAMS],
  ];
};

export const EmployeeDetailsPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { membershipId } = useParams<{ membershipId: string }>();
  const routes = useEmployeeDetailsPageRoutes();
  const location = useLocation();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    routes
      ?.map((elem, index) => {
        return location.pathname.includes(elem.path) && index;
      })
      .filter((e) => e)[0] || 0,
  );
  // useRegistrationGet
  const { data: employee, isLoading } =
    useMembershipRegistrationGet(membershipId);
  const { data: organisation } = useOrganisationGet(employee?.organisation_id, {
    query: { enabled: !!employee },
  });

  if (!employee || !organisation || isLoading) {
    return <Loader />;
  }

  const tabList = [
    {
      title: t(i18nKeys.common.budgets),
      component: (
        <EmployeeDetailsBudgets
          employee={employee}
          isOldGen={
            organisation?.organisation_scope ===
            OrganisationAllOfOrganisationScope.OLDGEN
          }
        />
      ),
      route: Routes.DASHBOARD_EMPLOYEES_DETAILS_BUDGETS,
    },
    {
      title: t(i18nKeys.common.expenses),
      component: (
        <EmployeeDetailsExpenses
          employee={employee}
          isOldGen={
            organisation?.organisation_scope ===
            OrganisationAllOfOrganisationScope.OLDGEN
          }
        />
      ),
      route: Routes.DASHBOARD_EMPLOYEES_DETAILS_EXPENSES,
    },
    {
      title: t(i18nKeys.common.card),
      component: <EmployeeDetailsCards employee={employee} />,
      route: Routes.DASHBOARD_EMPLOYEES_DETAILS_CARD,
    },
  ];

  const tabListWithProgram =
    organisation?.organisation_scope ===
    OrganisationAllOfOrganisationScope.OLDGEN
      ? tabList
      : tabList.concat({
          title: t(i18nKeys.common.programs),
          component: <EmployeeDetailsPrograms employee={employee} />,
          route: Routes.DASHBOARD_EMPLOYEES_DETAILS_PROGRAMS,
        });

  const tabChangeHandler = (index: number) => {
    setActiveTabIndex(index);
  };

  const getUrl = (index: number) =>
    addQueryParams(
      getPath(tabListWithProgram[index].route, {
        params: { membershipId: employee.membership_id },
      }),
      { page: 1, pageSize: 10 },
    );

  return (
    <DashboardLayout>
      <Breadcrumb mb="2rem">
        <BreadcrumbItem>
          <BreadcrumbLink
            variant="breadcrumb"
            as={Link}
            to={getPath(Routes.DASHBOARD)}
          >
            {t(i18nKeys.common.home)}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            variant="breadcrumb"
            as={Link}
            to={getPath(Routes.DASHBOARD_EMPLOYEES)}
          >
            {t(i18nKeys.common.employees)}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink variant="breadcrumb" as={Link} to="#">
            {capitalize(employee.first_name)} {capitalize(employee.last_name)}{' '}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Wrap>
        <WrapItem>
          <Button
            colorScheme="gray"
            as={Link}
            to={getPath(Routes.DASHBOARD_EMPLOYEES)}
          >
            Return
          </Button>
        </WrapItem>
      </Wrap>

      <EmployeeDetailsOverview
        employee={employee}
        isOldGen={
          organisation?.organisation_scope ===
          OrganisationAllOfOrganisationScope.OLDGEN
        }
      />
      <Tabs mt="2rem" onChange={tabChangeHandler} index={activeTabIndex}>
        <TabList>
          {tabListWithProgram.map(({ title }, index) => (
            <Tab
              textColor="ink.medium"
              fontSize="body2"
              fontFamily={theme.fonts.large}
              _hover={{
                textColor:
                  activeTabIndex === index
                    ? 'ink.black'
                    : 'main.secondary.normal',
              }}
              _selected={{
                textColor: 'ink.black',
                borderBottomColor: 'main.primary.normal',
              }}
              key={title}
              as={NavLink}
              to={getUrl(index)}
            >
              {title}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabListWithProgram.map(({ title, component }) => (
            <TabPanel key={title}>{component}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </DashboardLayout>
  );
};
