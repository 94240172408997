/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type QueryRegistrationStatusParameter =
  typeof QueryRegistrationStatusParameter[keyof typeof QueryRegistrationStatusParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QueryRegistrationStatusParameter = {
  CREATED: 'CREATED',
  VALIDATED: 'VALIDATED',
  CANCELED: 'CANCELED',
} as const;
