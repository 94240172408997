/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  ServicesGetResponse,
  ServicesGetParams,
  Service,
  ServiceDetails,
  ServiceDetailsParams,
} from '../../models';
import { customInstance } from '../../config';

/**
 * Due to technical reasons, we don't support pagination when "program_id" is provided. When "program_id" is provided, we always assume a single page with all results.
 */
export const servicesGet = (
  params?: ServicesGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ServicesGetResponse>({
    url: `/web/v2/services`,
    method: 'get',
    params,
    signal,
  });
};

export const getServicesGetQueryKey = (params?: ServicesGetParams) => [
  `/web/v2/services`,
  ...(params ? [params] : []),
];

export type ServicesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof servicesGet>>
>;
export type ServicesGetQueryError = unknown;

export const useServicesGet = <
  TData = Awaited<ReturnType<typeof servicesGet>>,
  TError = unknown,
>(
  params?: ServicesGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof servicesGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getServicesGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof servicesGet>>> = ({
    signal,
  }) => servicesGet(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof servicesGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Fetch a single service by ID
 */
export const serviceGet = (serviceId: string, signal?: AbortSignal) => {
  return customInstance<Service>({
    url: `/web/v2/services/${serviceId}`,
    method: 'get',
    signal,
  });
};

export const getServiceGetQueryKey = (serviceId: string) => [
  `/web/v2/services/${serviceId}`,
];

export type ServiceGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof serviceGet>>
>;
export type ServiceGetQueryError = unknown;

export const useServiceGet = <
  TData = Awaited<ReturnType<typeof serviceGet>>,
  TError = unknown,
>(
  serviceId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof serviceGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getServiceGetQueryKey(serviceId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof serviceGet>>> = ({
    signal,
  }) => serviceGet(serviceId, signal);

  const query = useQuery<Awaited<ReturnType<typeof serviceGet>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!serviceId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get the details of a service
 */
export const serviceDetails = (
  serviceId: string,
  params: ServiceDetailsParams,
  signal?: AbortSignal,
) => {
  return customInstance<ServiceDetails>({
    url: `/web/v2/services/${serviceId}/details`,
    method: 'get',
    params,
    signal,
  });
};

export const getServiceDetailsQueryKey = (
  serviceId: string,
  params: ServiceDetailsParams,
) => [`/web/v2/services/${serviceId}/details`, ...(params ? [params] : [])];

export type ServiceDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof serviceDetails>>
>;
export type ServiceDetailsQueryError = unknown;

export const useServiceDetails = <
  TData = Awaited<ReturnType<typeof serviceDetails>>,
  TError = unknown,
>(
  serviceId: string,
  params: ServiceDetailsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof serviceDetails>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getServiceDetailsQueryKey(serviceId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof serviceDetails>>> = ({
    signal,
  }) => serviceDetails(serviceId, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof serviceDetails>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!serviceId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
