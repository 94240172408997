/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type QueryReportTimeScaleParameter =
  typeof QueryReportTimeScaleParameter[keyof typeof QueryReportTimeScaleParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QueryReportTimeScaleParameter = {
  YEAR: 'YEAR',
  MONTH: 'MONTH',
  WEEK: 'WEEK',
  DAY: 'DAY',
} as const;
