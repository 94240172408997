/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { PolicyActionAction } from './policyActionAction';
import type { PolicyActionActionRole } from './policyActionActionRole';

export interface PolicyAction {
  action: PolicyActionAction;
  action_role: PolicyActionActionRole;
  active: boolean;
}
