import { Text } from '@chakra-ui/react';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { capitalize } from '@libs/core/utils';
import { Pager, Table, Tag, usePager } from '@libs/ui/components';
import { TableHeaders } from '@libs/ui/components/table/table.types';
import { useDrawer, useTable } from '@libs/ui/components/table/table.utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProgramStatusToTagStatus } from '../../employee/employee.utils';
import { useProgramsGet } from '@libs/api-v2/endpoints/programs/programs';
import { ProgramServicesDrawer } from '../../employee/details/components/program-services-drawer';
import { useIcon } from '@libs/core/theme/utils';
import { DrawerType } from '@libs/dashboard-core/pages/dashboard/dashboard.utils';
import { ProgramGroupsTable } from './programgroup-table';

type OrganisationDetailsProgramsProps = {
  organisationId?: string;
};

export const ProgramsTable = ({
  organisationId,
}: OrganisationDetailsProgramsProps) => {
  const { t } = useTranslation();
  const { pageNumber, pageSize, setPageNumber, setPageSize } = usePager();
  const { setSearchQuery, useQueryParams } = useTable({
    setPageNumber,
    setPageSize,
  });
  const EyeIcon = useIcon('Eye');
  const { drawerHandler, isOpen, onClose, activeDrawerIndex } = useDrawer();

  const submitHandler = ({ q }) => {
    setSearchQuery(q);
  };

  const {
    // is false when fetching cached values
    isLoading,
    refetch,
    data: programs,
    // defaults to true so long as enabled is set to false
    // is true if the query is currently fetching, including background fetching
    isFetching,
  } = useProgramsGet(
    {
      organisation_id: organisationId,
      page_number: pageNumber,
      page_size: pageSize,
    },
    { query: { keepPreviousData: true } },
  );

  useQueryParams({
    pageSize,
    pageNumber,
    setPageSize,
    refetch,
    totalPages: programs?.meta.total_pages,
  });

  const headers: TableHeaders = [
    {
      name: 'name',
      title: t(i18nKeys.common.program),
    },
    {
      name: 'start_at',
      title: t(i18nKeys.common.start_at),
    },
    { name: 'status', title: t(i18nKeys.common.status) },
  ];

  return (
    <>
      <Table onSubmit={submitHandler} headers={headers}>
        <Table.TBody
          isLoading={(isLoading || isFetching) && !programs}
          colSpan={headers.length}
          isEmpty={
            !isLoading &&
            !isFetching &&
            (programs?.data.length === 0 || !programs)
          }
        >
          {programs?.data.map(({ id, name, start_date, status }, index) => (
            <Table.TR key={id} selectable index={id}>
              <Table.TD>
                <Text size="Body2">{capitalize(name)} </Text>
                <ProgramGroupsTable programId={id} />
              </Table.TD>
              <Table.Date>{start_date}</Table.Date>
              <Table.TD>
                <Tag
                  hasIcon
                  tooltipLabel={
                    <Text size="Small">
                      {t(i18nKeys.common.activation_status[status])}
                    </Text>
                  }
                  status={ProgramStatusToTagStatus[status]}
                />
              </Table.TD>
              <Table.Action
                actions={[
                  {
                    onClick: () =>
                      drawerHandler({
                        index,
                        drawerType: DrawerType.conversion,
                      }),
                    icon: <EyeIcon width="2rem" height="2rem" />,
                  },
                ]}
              />
              <Table.Action
                actions={[
                  {
                    onClick: () => drawerHandler({ index }),
                    icon: <EyeIcon width="2rem" height="2rem" />,
                  },
                ]}
              />
            </Table.TR>
          ))}
        </Table.TBody>
      </Table>
      {programs && programs?.meta.total_pages > 1 && (
        <Pager
          setPageNumber={setPageNumber}
          total_items={programs.meta.total_items}
          page_index={programs.meta.page_index}
          total_pages={programs.meta.total_pages}
          mt="2rem"
          page_size={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          isFetching={isFetching}
        />
      )}
      {isOpen && programs?.data[activeDrawerIndex] && (
        <ProgramServicesDrawer
          program={programs?.data[activeDrawerIndex]}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};
