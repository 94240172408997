import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  useClipboard,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { useOrganisationGet } from '@libs/api/endpoints';
import { useCardsGet } from '@libs/api-v2/endpoints';
import { CardStatus } from '@libs/api/models';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { useIcon } from '@libs/core/theme/utils';
import { capitalize } from '@libs/core/utils';
import { getPath, Routes } from '@libs/dashboard-core/routes';
import { StatusToTagStatus } from '@libs/dashboard-core/utils/status';
import { Link, PageHeader, Tag } from '@libs/ui/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeDetailsDrawer } from './employee-details-drawer';
import { Registration } from '@libs/api-v2/models';

type EmployeeDetailsOverviewProps = {
  employee: Registration;
  isOldGen: boolean;
};

export const EmployeeDetailsOverview = ({
  employee,
  isOldGen,
}: EmployeeDetailsOverviewProps) => {
  const UserGroup = useIcon('UserGroup');
  const OrganisationIcon = useIcon('Organisation');
  const User = useIcon('User');
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const theme = useTheme();
  const { hasCopied, onCopy } = useClipboard(employee.membership_id);

  const { data: cards, isLoading } = useCardsGet({
    membership_id: employee.membership_id,
  });

  const { data: organisation, isLoading: isOrganisationLoading } =
    useOrganisationGet(employee.organisation_id);

  // Checks that at least one card is unlocked in the list
  const isCardActive =
    cards?.data.filter(({ card_status }) => card_status === CardStatus.UNLOCK)
      .length > 0;

  return (
    <Flex alignItems="center">
      <Avatar
        mr="1rem"
        w="6rem"
        h="6rem"
        bgColor="ink.white"
        icon={
          <User
            height="3.5rem"
            width="3.5rem"
            fill={theme.colors.main.secondary.normal}
          />
        }
      />
      <Box>
        <Flex align="center">
          <PageHeader
            size="Large"
            title={
              employee.first_name && employee.last_name
                ? `${capitalize(employee.first_name)} ${capitalize(
                    employee.last_name,
                  )} ${isOldGen ? ' (old gen) ' : ' (next gen)'}`
                : ''
            }
          />
          <Tag
            tooltipLabel={
              <Text size="Small">
                {t(i18nKeys.common.TagStatus[employee.status])}
              </Text>
            }
            ml="1rem"
            status={StatusToTagStatus[employee.status]}
            hasIcon
          />
          {!isLoading && cards?.data.length > 0 && (
            <Tag
              h="3rem"
              px="1rem"
              py=".5rem"
              borderRadius="full"
              backgroundColor={`status.${
                isCardActive ? 'positive' : 'negative'
              }.type`}
            >
              <Text size="Small" color="ink.white" pr=".5rem">
                {t(
                  isCardActive
                    ? i18nKeys.operator.employees.details.card.active
                    : i18nKeys.operator.employees.details.card.blocked,
                )}
              </Text>
            </Tag>
          )}
        </Flex>
        <Flex>
          <Text width="auto">{employee.membership_id}</Text>
          <Button onClick={onCopy}>{hasCopied ? 'Copied' : 'Copy'}</Button>
        </Flex>
        <Flex>
          <Flex align="center" mr="2rem">
            <Box w="2rem" mr=".5rem" fill="ink.dark">
              <UserGroup />
            </Box>
            <Text color="ink.dark" size="Body2">
              {employee.job_title && employee.role
                ? t(i18nKeys.operator.employees.details.role_title, {
                    function: employee.job_title,
                    role: t(i18nKeys.common.role[employee.role]),
                  })
                : employee.job_title || t(i18nKeys.common.role[employee.role])}
            </Text>
            {!isOrganisationLoading && organisation && (
              <Link
                to={getPath(Routes.DASHBOARD_ORGANISATIONS_DETAILS, {
                  params: { organisationId: employee.organisation_id },
                })}
              >
                <Flex align="center" mr="2rem">
                  <Box ml="2rem" w="2rem" mr=".5rem" fill="ink.dark">
                    <OrganisationIcon />
                  </Box>
                  <Text
                    _hover={{ color: 'link.hover' }}
                    color="ink.dark"
                    size="Body2"
                  >
                    {organisation.legal_name}
                  </Text>
                </Flex>
              </Link>
            )}
          </Flex>
          <Button
            variant="border"
            width="auto"
            size="small"
            color="ink.darker"
            onClick={onOpen}
          >
            {t(i18nKeys.common.edit)}
          </Button>
        </Flex>
      </Box>

      <EmployeeDetailsDrawer
        isOpen={isOpen}
        onClose={onClose}
        employee={employee}
      />
    </Flex>
  );
};
