/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type PolicyActionActionRole =
  typeof PolicyActionActionRole[keyof typeof PolicyActionActionRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PolicyActionActionRole = {
  HR_MANAGER: 'HR_MANAGER',
  MANAGER: 'MANAGER',
} as const;
