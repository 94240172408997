/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  MerchantsGetResponse,
  GetMerchantsParams,
  Merchant,
  UpdateMerchantRequest,
} from '../../models';
import { customInstance } from '../../config';

export const getMerchants = (
  params?: GetMerchantsParams,
  signal?: AbortSignal,
) => {
  return customInstance<MerchantsGetResponse>({
    url: `/web/v2/merchants`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetMerchantsQueryKey = (params?: GetMerchantsParams) => [
  `/web/v2/merchants`,
  ...(params ? [params] : []),
];

export type GetMerchantsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMerchants>>
>;
export type GetMerchantsQueryError = unknown;

export const useGetMerchants = <
  TData = Awaited<ReturnType<typeof getMerchants>>,
  TError = unknown,
>(
  params?: GetMerchantsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMerchants>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMerchantsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMerchants>>> = ({
    signal,
  }) => getMerchants(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getMerchants>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Allows to assign a merchant to service/provider
 */
export const updateMerchant = (
  merchantId: string,
  updateMerchantRequest: UpdateMerchantRequest,
) => {
  return customInstance<Merchant>({
    url: `/web/v2/merchants/${merchantId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: updateMerchantRequest,
  });
};

export type UpdateMerchantMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateMerchant>>
>;
export type UpdateMerchantMutationBody = UpdateMerchantRequest;
export type UpdateMerchantMutationError = unknown;

export const useUpdateMerchant = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMerchant>>,
    TError,
    { merchantId: string; data: UpdateMerchantRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateMerchant>>,
    { merchantId: string; data: UpdateMerchantRequest }
  > = (props) => {
    const { merchantId, data } = props ?? {};

    return updateMerchant(merchantId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateMerchant>>,
    TError,
    { merchantId: string; data: UpdateMerchantRequest },
    TContext
  >(mutationFn, mutationOptions);
};
