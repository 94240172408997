/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type Resource = typeof Resource[keyof typeof Resource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Resource = {
  expenses: 'expenses',
  km_allowances: 'km_allowances',
} as const;
