/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { ActivationStatus } from './activationStatus';

export type ProgramServiceAllOf = {
  status?: ActivationStatus;
  is_splittable?: boolean;
  is_default_for_provider?: boolean;
  min_split_amount?: number;
  employer_reimbursement_percentage?: number;
};
