/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type QueryHasMembershipActionParameter =
  typeof QueryHasMembershipActionParameter[keyof typeof QueryHasMembershipActionParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QueryHasMembershipActionParameter = {
  SUSPENSION: 'SUSPENSION',
  REACTIVATION: 'REACTIVATION',
} as const;
