/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';

export interface UpdateMerchantRequest {
  merchant_name: string;
  service_id: Uuid;
  provider_id: Uuid;
}
