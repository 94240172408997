/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

/**
 * Registrations should only have a single role. E.g. a user which is both an EMPLOYEE and ADMIN will be excluded. Can be used together with the roles filter.
 */
export type QuerySingleRoleParameter = boolean;
