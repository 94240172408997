/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';

/**
 * Simple object which only contains an ID. Can be used in case you want to keep the same structure as an object, but want to show only the ID is taken into account. E.g. used in policy for services.
 */
export interface OnlyId {
  id: Uuid;
}
