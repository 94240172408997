import { Button } from '@chakra-ui/button';
import { Box, Flex, Heading, Text } from '@chakra-ui/layout';
import {
  getOrganisationGetQueryKey,
  organisationUpdate,
} from '@libs/api/endpoints';
import { KmAllowanceType, Organisation } from '@libs/api/models';
import { formatCurrency } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { validateSchema } from '@libs/core/utils/validation';
import {
  Drawer,
  FormSubmitData,
  handleFormSubmit,
  InputField,
  SelectField,
  SwitchField,
  useToast,
} from '@libs/ui/components';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OrganisationSettingsFormData } from '../../organisation.types';
import { validationUpdateOrganisationSettingsSchema } from '../../organisation.validations';

type SettingsDrawerProps = {
  organisation: Organisation;
  isOpen: boolean;
  onClose: () => void;
};

export const SettingsDrawer = ({
  organisation,
  isOpen,
  onClose,
}: SettingsDrawerProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: updateOrganisationSettings, isLoading } = useMutation(
    ({ values }: FormSubmitData<OrganisationSettingsFormData>) => {
      return organisationUpdate(organisation.id, {
        ...organisation,
        ...values,
        configuration: {
          ...values.configuration,
          automatic_expense_approval_limits: {
            ...values.configuration.automatic_expense_approval_limits,
            app_pro_expense:
              values.configuration.automatic_expense_approval_limits
                .app_pro_expense * 100,
            card_pro_expense:
              values.configuration.automatic_expense_approval_limits
                .card_pro_expense * 100,
          },
          km_allowance: {
            ...values.configuration.km_allowance,
            bike_fee_per_km:
              values.configuration.km_allowance.bike_fee_per_km * 100,
            car_fee_per_km:
              values.configuration.km_allowance.car_fee_per_km * 100,
          },
        },
      });
    },
    {
      onSuccess: () => {
        const queryKey = getOrganisationGetQueryKey(organisation.id);
        queryClient.invalidateQueries(queryKey);
        queryClient.fetchQuery(queryKey);

        toast({
          title: t(
            i18nKeys.operator.organisations.details.settings.update_success
              .title,
          ),
          content: t(
            i18nKeys.operator.organisations.details.settings.update_success
              .content,
          ),
        });
        onClose();
      },
    },
  );

  const KmAllowanceTypeOptions = () => (
    <>
      {Object.values(KmAllowanceType).map((type) => (
        <option key={type} value={type}>
          {t(i18nKeys.common.KmAllowanceType[type])}
        </option>
      ))}
    </>
  );

  const initialValues = {
    configuration: {
      automatic_expense_approval_limits: {
        card_pro_expense:
          Number(
            formatCurrency({
              number:
                organisation.configuration?.automatic_expense_approval_limits
                  .card_pro_expense,
            }),
          ) || undefined,
        app_pro_expense:
          Number(
            formatCurrency({
              number:
                organisation.configuration?.automatic_expense_approval_limits
                  .app_pro_expense,
            }),
          ) || undefined,
      },
      km_allowance: {
        enabled: organisation?.configuration?.km_allowance?.enabled,
        bike_allowance_type:
          organisation?.configuration?.km_allowance?.bike_allowance_type,
        bike_fee_per_km:
          Number(
            formatCurrency({
              number:
                organisation?.configuration?.km_allowance?.bike_fee_per_km,
            }),
          ) || undefined,
        car_allowance_type:
          organisation?.configuration?.km_allowance?.car_allowance_type,
        car_fee_per_km:
          Number(
            formatCurrency({
              number: organisation?.configuration?.km_allowance?.car_fee_per_km,
            }),
          ) || undefined,
      },
    },
    social_secretary_reference: organisation.social_secretary_reference,
  };

  return (
    <Drawer
      title={t(i18nKeys.common.settings)}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <Formik<OrganisationSettingsFormData>
        initialValues={initialValues}
        onSubmit={handleFormSubmit(updateOrganisationSettings)}
        validate={validateSchema(validationUpdateOrganisationSettingsSchema)}
      >
        {({ isValid, dirty, values }) => (
          <Form>
            <Drawer.Body>
              <Box mt="2rem">
                <Heading size="Title3">
                  {t(i18nKeys.common.social_secretary_reference)}
                </Heading>
                <InputField
                  name="social_secretary_reference"
                  label={t(i18nKeys.common.social_secretary_reference)}
                />
              </Box>
              <Box mt="4rem">
                <Heading size="Title3">
                  {t(
                    i18nKeys.operator.organisations.details.settings
                      .approval_limits,
                  )}
                </Heading>
                <InputField
                  type="number"
                  name="configuration.automatic_expense_approval_limits.card_pro_expense"
                  label={t(
                    i18nKeys.common.configuration
                      .automatic_expense_approval_limits.card_pro_expense,
                  )}
                />
                <InputField
                  type="number"
                  name="configuration.automatic_expense_approval_limits.app_pro_expense"
                  label={t(
                    i18nKeys.common.configuration
                      .automatic_expense_approval_limits.app_pro_expense,
                  )}
                />
              </Box>
              <Box mt="4rem">
                <Heading size="Title3">
                  {t(
                    i18nKeys.operator.organisations.details.settings
                      .km_allowance,
                  )}
                </Heading>
                <SwitchField
                  name="configuration.km_allowance.enabled"
                  label={t(i18nKeys.common.configuration.km_allowance.enabled)}
                />
                <Text size="Body2Bold" color="darker">
                  {t(
                    i18nKeys.operator.organisations.details.settings
                      .bike_coefficients,
                  )}
                </Text>
                <SelectField
                  name="configuration.km_allowance.bike_allowance_type"
                  label={t(i18nKeys.common.type)}
                  isRequired={values.configuration.km_allowance.enabled}
                >
                  <KmAllowanceTypeOptions />
                </SelectField>
                <InputField
                  type="number"
                  name="configuration.km_allowance.bike_fee_per_km"
                  label={t(
                    i18nKeys.common.configuration.km_allowance.fee_per_km,
                  )}
                  isDisabled={
                    values.configuration.km_allowance.bike_allowance_type ===
                    KmAllowanceType.LEGAL
                  }
                  isRequired={
                    values.configuration.km_allowance.enabled &&
                    values.configuration.km_allowance.bike_allowance_type !==
                      KmAllowanceType.LEGAL
                  }
                />
                <Text mt="2rem" size="Body2Bold" color="darker">
                  {t(
                    i18nKeys.operator.organisations.details.settings
                      .car_coefficients,
                  )}
                </Text>
                <SelectField
                  name="configuration.km_allowance.car_allowance_type"
                  label={t(i18nKeys.common.type)}
                  isRequired={values.configuration.km_allowance.enabled}
                >
                  <KmAllowanceTypeOptions />
                </SelectField>
                <InputField
                  type="number"
                  name="configuration.km_allowance.car_fee_per_km"
                  label={t(
                    i18nKeys.common.configuration.km_allowance.fee_per_km,
                  )}
                  isDisabled={
                    values.configuration.km_allowance.car_allowance_type ===
                    KmAllowanceType.LEGAL
                  }
                  isRequired={
                    values.configuration.km_allowance.enabled &&
                    values.configuration.km_allowance.car_allowance_type !==
                      KmAllowanceType.LEGAL
                  }
                />
              </Box>
            </Drawer.Body>
            <Drawer.Footer>
              <Flex w="100%" justify="space-between">
                <Button
                  size="body2"
                  w="auto"
                  variant="border"
                  onClick={onClose}
                >
                  {t(i18nKeys.common.cancel)}
                </Button>
                <Button
                  type="submit"
                  size="body2"
                  w="auto"
                  variant="primary"
                  disabled={!isValid || !dirty}
                  isLoading={isLoading}
                  loadingText={t(i18nKeys.common.submit)}
                >
                  {t(i18nKeys.common.submit)}
                </Button>
              </Flex>
            </Drawer.Footer>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};
