import React from 'react';
import { ExpensesTableOldGen } from './expenses-table-oldgen';
import { ExpensesTable } from '../../../expense/components/expenses-table';
import { Registration } from '@libs/api-v2/models';

type EmployeeDetailsExpensesProps = {
  employee: Registration;
  isOldGen?: boolean;
};

export const EmployeeDetailsExpenses = ({
  employee,
  isOldGen = false,
}: EmployeeDetailsExpensesProps) => {
  return isOldGen ? (
    <ExpensesTableOldGen membershipId={employee?.membership_id} />
  ) : (
    <ExpensesTable membershipId={employee?.membership_id} />
  );
};
