/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type PaymentMethodParam =
  typeof PaymentMethodParam[keyof typeof PaymentMethodParam];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentMethodParam = {
  APP: 'APP',
  CARD: 'CARD',
  REFUND: 'REFUND',
} as const;
