/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { QueryOrganisationIdParameter } from './component-parameters/queryOrganisationIdParameter';
import type { QueryQParameter } from './component-parameters/queryQParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';
import type { QueryRolesParameter } from './component-parameters/queryRolesParameter';

export type InvitationsGetParams = {
  organisation_id?: QueryOrganisationIdParameter;
  q?: QueryQParameter;
  page_number?: QueryPageNumberParameter;
  page_size?: QueryPageSizeParameter;
  roles?: QueryRolesParameter;
};
