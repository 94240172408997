/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Email } from './component-common/email';
import type { Language } from './component-common/language';
import type { Role } from './role';
import type { Membership } from './membership';

export interface LoggedUser {
  /** user ID */
  id: string;
  first_name?: string;
  last_name?: string;
  email?: Email;
  language?: Language;
  roles: Role[];
  memberships?: Membership[];
}
