/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  TeamsGetResponse,
  Error404NotFoundResponse,
  TeamsListParams,
  TeamUpSertResponse,
  Error400TeamNameAlreadyExistsResponse,
  Error403ForbiddenResponse,
  TeamPostRequest,
  TeamWithMeta,
  TeamGetParams,
  TeamPutRequest,
  TeamMembersGetResponse,
  TeamMembersListParams,
  Team,
  Error400TeamMembershipNotManagerResponse,
} from '../../models';
import type { Error, ErrorResponse } from '../../models/component-common';
import { customInstance } from '../../config';

/**
 * Fetch all teams based on current membership role and organisation, can be limited via the organisation_id querystring.
- employee will see only the team he belongs to
- manager will see only the team for which he his manager and belongs to (!)
- administration will see all teams belonging to the same organisation
- operator will see all teams cross organisation except if one specific organisation is specified

 */
export const teamsList = (params?: TeamsListParams, signal?: AbortSignal) => {
  return customInstance<TeamsGetResponse>({
    url: `/web/v2/teams`,
    method: 'get',
    params,
    signal,
  });
};

export const getTeamsListQueryKey = (params?: TeamsListParams) => [
  `/web/v2/teams`,
  ...(params ? [params] : []),
];

export type TeamsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsList>>
>;
export type TeamsListQueryError = Error404NotFoundResponse;

export const useTeamsList = <
  TData = Awaited<ReturnType<typeof teamsList>>,
  TError = Error404NotFoundResponse,
>(
  params?: TeamsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsList>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeamsListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teamsList>>> = ({
    signal,
  }) => teamsList(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof teamsList>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Create an empty new named team for an organisation.
- administrator can only create team for its own organisation.
- operator can create a team for any organisation

throw an 404 error if organisation does not exist.

 */
export const teamsPost = (teamPostRequest: TeamPostRequest) => {
  return customInstance<TeamUpSertResponse>({
    url: `/web/v2/teams`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: teamPostRequest,
  });
};

export type TeamsPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsPost>>
>;
export type TeamsPostMutationBody = TeamPostRequest;
export type TeamsPostMutationError =
  | Error400TeamNameAlreadyExistsResponse
  | Error403ForbiddenResponse
  | Error404NotFoundResponse;

export const useTeamsPost = <
  TError =
    | Error400TeamNameAlreadyExistsResponse
    | Error403ForbiddenResponse
    | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsPost>>,
    TError,
    { data: TeamPostRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsPost>>,
    { data: TeamPostRequest }
  > = (props) => {
    const { data } = props ?? {};

    return teamsPost(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof teamsPost>>,
    TError,
    { data: TeamPostRequest },
    TContext
  >(mutationFn, mutationOptions);
};
export const teamGet = (
  teamId: string,
  params?: TeamGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<TeamWithMeta>({
    url: `/web/v2/teams/${teamId}`,
    method: 'get',
    params,
    signal,
  });
};

export const getTeamGetQueryKey = (teamId: string, params?: TeamGetParams) => [
  `/web/v2/teams/${teamId}`,
  ...(params ? [params] : []),
];

export type TeamGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamGet>>
>;
export type TeamGetQueryError = Error404NotFoundResponse;

export const useTeamGet = <
  TData = Awaited<ReturnType<typeof teamGet>>,
  TError = Error404NotFoundResponse,
>(
  teamId: string,
  params?: TeamGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof teamGet>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeamGetQueryKey(teamId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teamGet>>> = ({
    signal,
  }) => teamGet(teamId, params, signal);

  const query = useQuery<Awaited<ReturnType<typeof teamGet>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!teamId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const teamPut = (teamId: string, teamPutRequest: TeamPutRequest) => {
  return customInstance<TeamUpSertResponse>({
    url: `/web/v2/teams/${teamId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: teamPutRequest,
  });
};

export type TeamPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamPut>>
>;
export type TeamPutMutationBody = TeamPutRequest;
export type TeamPutMutationError =
  | Error400TeamNameAlreadyExistsResponse
  | Error404NotFoundResponse;

export const useTeamPut = <
  TError = Error400TeamNameAlreadyExistsResponse | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamPut>>,
    TError,
    { teamId: string; data: TeamPutRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamPut>>,
    { teamId: string; data: TeamPutRequest }
  > = (props) => {
    const { teamId, data } = props ?? {};

    return teamPut(teamId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof teamPut>>,
    TError,
    { teamId: string; data: TeamPutRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Remove a team from an organisation. The team assigment of all members, managers and HR managers which were part of this team will be removed. Except for this, they will remain untouched.
 */
export const teamDelete = (teamId: string) => {
  return customInstance<void>({
    url: `/web/v2/teams/${teamId}`,
    method: 'delete',
  });
};

export type TeamDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamDelete>>
>;

export type TeamDeleteMutationError = Error404NotFoundResponse;

export const useTeamDelete = <
  TError = Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamDelete>>,
    TError,
    { teamId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamDelete>>,
    { teamId: string }
  > = (props) => {
    const { teamId } = props ?? {};

    return teamDelete(teamId);
  };

  return useMutation<
    Awaited<ReturnType<typeof teamDelete>>,
    TError,
    { teamId: string },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * All members of a Team

 */
export const teamMembersList = (
  teamId: string,
  params?: TeamMembersListParams,
  signal?: AbortSignal,
) => {
  return customInstance<TeamMembersGetResponse>({
    url: `/web/v2/teams/${teamId}/members`,
    method: 'get',
    params,
    signal,
  });
};

export const getTeamMembersListQueryKey = (
  teamId: string,
  params?: TeamMembersListParams,
) => [`/web/v2/teams/${teamId}/members`, ...(params ? [params] : [])];

export type TeamMembersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamMembersList>>
>;
export type TeamMembersListQueryError = Error404NotFoundResponse;

export const useTeamMembersList = <
  TData = Awaited<ReturnType<typeof teamMembersList>>,
  TError = Error404NotFoundResponse,
>(
  teamId: string,
  params?: TeamMembersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamMembersList>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTeamMembersListQueryKey(teamId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teamMembersList>>> = ({
    signal,
  }) => teamMembersList(teamId, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof teamMembersList>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!teamId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const teamMemberPut = (teamId: string, membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/teams/${teamId}/members/${membershipId}`,
    method: 'put',
  });
};

export type TeamMemberPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamMemberPut>>
>;

export type TeamMemberPutMutationError = Error | Error404NotFoundResponse;

export const useTeamMemberPut = <
  TError = Error | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamMemberPut>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamMemberPut>>,
    { teamId: string; membershipId: string }
  > = (props) => {
    const { teamId, membershipId } = props ?? {};

    return teamMemberPut(teamId, membershipId);
  };

  return useMutation<
    Awaited<ReturnType<typeof teamMemberPut>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const teamMemberDelete = (teamId: string, membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/teams/${teamId}/members/${membershipId}`,
    method: 'delete',
  });
};

export type TeamMemberDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamMemberDelete>>
>;

export type TeamMemberDeleteMutationError = Error404NotFoundResponse;

export const useTeamMemberDelete = <
  TError = Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamMemberDelete>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamMemberDelete>>,
    { teamId: string; membershipId: string }
  > = (props) => {
    const { teamId, membershipId } = props ?? {};

    return teamMemberDelete(teamId, membershipId);
  };

  return useMutation<
    Awaited<ReturnType<typeof teamMemberDelete>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const teamManagerPut = (teamId: string, membershipId: string) => {
  return customInstance<Team>({
    url: `/web/v2/teams/${teamId}/managers/${membershipId}`,
    method: 'put',
  });
};

export type TeamManagerPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamManagerPut>>
>;

export type TeamManagerPutMutationError = Error;

export const useTeamManagerPut = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamManagerPut>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamManagerPut>>,
    { teamId: string; membershipId: string }
  > = (props) => {
    const { teamId, membershipId } = props ?? {};

    return teamManagerPut(teamId, membershipId);
  };

  return useMutation<
    Awaited<ReturnType<typeof teamManagerPut>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const teamManagerDelete = (teamId: string, membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/teams/${teamId}/managers/${membershipId}`,
    method: 'delete',
  });
};

export type TeamManagerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamManagerDelete>>
>;

export type TeamManagerDeleteMutationError = unknown;

export const useTeamManagerDelete = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamManagerDelete>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamManagerDelete>>,
    { teamId: string; membershipId: string }
  > = (props) => {
    const { teamId, membershipId } = props ?? {};

    return teamManagerDelete(teamId, membershipId);
  };

  return useMutation<
    Awaited<ReturnType<typeof teamManagerDelete>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const teamHrmanagerPut = (teamId: string, membershipId: string) => {
  return customInstance<Team>({
    url: `/web/v2/teams/${teamId}/hr_managers/${membershipId}`,
    method: 'put',
  });
};

export type TeamHrmanagerPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamHrmanagerPut>>
>;

export type TeamHrmanagerPutMutationError =
  Error400TeamMembershipNotManagerResponse;

export const useTeamHrmanagerPut = <
  TError = Error400TeamMembershipNotManagerResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamHrmanagerPut>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamHrmanagerPut>>,
    { teamId: string; membershipId: string }
  > = (props) => {
    const { teamId, membershipId } = props ?? {};

    return teamHrmanagerPut(teamId, membershipId);
  };

  return useMutation<
    Awaited<ReturnType<typeof teamHrmanagerPut>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const teamHrmanagerDelete = (teamId: string, membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/teams/${teamId}/hr_managers/${membershipId}`,
    method: 'delete',
  });
};

export type TeamHrmanagerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamHrmanagerDelete>>
>;

export type TeamHrmanagerDeleteMutationError = ErrorResponse;

export const useTeamHrmanagerDelete = <
  TError = ErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamHrmanagerDelete>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamHrmanagerDelete>>,
    { teamId: string; membershipId: string }
  > = (props) => {
    const { teamId, membershipId } = props ?? {};

    return teamHrmanagerDelete(teamId, membershipId);
  };

  return useMutation<
    Awaited<ReturnType<typeof teamHrmanagerDelete>>,
    TError,
    { teamId: string; membershipId: string },
    TContext
  >(mutationFn, mutationOptions);
};
