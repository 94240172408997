/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { TeamMember } from './teamMember';
import type { Meta } from './meta';

export interface TeamMembersGetResponse {
  data?: TeamMember[];
  meta?: Meta;
}
