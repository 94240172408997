/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type { WalletGetResponse, GetWalletParams } from '../../models';
import { customInstance } from '../../config';

export const getWallet = (params?: GetWalletParams, signal?: AbortSignal) => {
  return customInstance<WalletGetResponse>({
    url: `/web/v2/wallets`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetWalletQueryKey = (params?: GetWalletParams) => [
  `/web/v2/wallets`,
  ...(params ? [params] : []),
];

export type GetWalletQueryResult = NonNullable<
  Awaited<ReturnType<typeof getWallet>>
>;
export type GetWalletQueryError = unknown;

export const useGetWallet = <
  TData = Awaited<ReturnType<typeof getWallet>>,
  TError = unknown,
>(
  params?: GetWalletParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getWallet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWalletQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWallet>>> = ({
    signal,
  }) => getWallet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getWallet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
