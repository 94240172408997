/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { CreateJourneyRequestConfiguration } from './createJourneyRequestConfiguration';

/**
 * for now, it's specific to km_allowance
 */
export interface CreateJourneyRequest {
  membership_id: string;
  program_id: string;
  product_id: string;
  expenses_at: string[];
  configuration: CreateJourneyRequestConfiguration;
}
