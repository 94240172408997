/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { ProductAllOfAttributes } from './productAllOfAttributes';
import type { ActivationStatus } from './activationStatus';
import type { ProductCategory } from './productCategory';
import type { Provider } from './provider';
import type { PaymentMethods } from './paymentMethods';

export type ProductAllOf = {
  readonly id?: string;
  readonly name?: string;
  /** Specific attributes of a product */
  readonly attributes?: ProductAllOfAttributes;
  readonly status?: ActivationStatus;
  readonly category?: ProductCategory;
  provider?: Provider;
  payment_methods?: PaymentMethods;
};
