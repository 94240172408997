/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  MembershipSummaryTopupListResponse,
  TopupsQueryParams,
} from '../../models';
import { customInstance } from '../../config';

export const topupsQuery = (
  params?: TopupsQueryParams,
  signal?: AbortSignal,
) => {
  return customInstance<MembershipSummaryTopupListResponse>({
    url: `/web/v2/topups`,
    method: 'get',
    params,
    signal,
  });
};

export const getTopupsQueryQueryKey = (params?: TopupsQueryParams) => [
  `/web/v2/topups`,
  ...(params ? [params] : []),
];

export type TopupsQueryQueryResult = NonNullable<
  Awaited<ReturnType<typeof topupsQuery>>
>;
export type TopupsQueryQueryError = unknown;

export const useTopupsQuery = <
  TData = Awaited<ReturnType<typeof topupsQuery>>,
  TError = unknown,
>(
  params?: TopupsQueryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof topupsQuery>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTopupsQueryQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof topupsQuery>>> = ({
    signal,
  }) => topupsQuery(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof topupsQuery>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};
