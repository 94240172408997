/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ExportFormat = typeof ExportFormat[keyof typeof ExportFormat];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExportFormat = {
  CSV: 'CSV',
  EXCEL: 'EXCEL',
} as const;
