/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { QueryUnassignedParameter } from './queryUnassignedParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';
import type { QuerySortDefinitionParameter } from './component-parameters/querySortDefinitionParameter';
import type { SearchMerchantNameParameter } from './searchMerchantNameParameter';

export type GetMerchantsParams = {
  unassigned?: QueryUnassignedParameter;
  page_number?: QueryPageNumberParameter;
  page_size?: QueryPageSizeParameter;
  sort?: QuerySortDefinitionParameter;
  search_merchant_name?: SearchMerchantNameParameter;
};
