/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  ProgramTemplatesGetResponse,
  ProgramTemplatesGetParams,
  ProgramTemplate,
  ProgramWithMeta,
  Error404NotFoundResponse,
  ProgramsGetResponse,
  ProgramsGetParams,
  Program,
  ProgramGroupGetResponse,
  ProgramGroupsGetParams,
  ProgramGroup,
  ProgramGroupsUpdateRequest,
  ProgramGroupWithMeta,
  ProgramGroupGetParams,
  ProgramGroupData,
  MembershipSummary,
  ProgramGroupLimit,
  ProgramGroupLimitSimulationParams,
  ProgramGroupLimitWithMeta,
  ProgramGroupLimitGetParams,
  ProgramGroupLimitUpdateParams,
  Error400LimitInvalidPayloadResponse,
  Error403LimitForbiddenResponse,
  Error409LimitAlreadyExistsResponse,
  ProgramGroupLimitCreateParams,
  ProgramGroupLimitDeleteParams,
  ModifyDisplayBudgetLimitRequest,
  ProgramGroupLimitDisplayPutParams,
  ProductsGetResponse,
  ProgramProductsGetParams,
  ProgramProduct,
  ProgramProductConfiguration,
  ProgramServicesGetResponse,
  ProgramServicesGetParams,
  ProgramServicesUpdateResponse,
  ProgramServicesUpdateRequest,
  ProgramServiceUpdateRequest,
} from '../../models';
import type { Error } from '../../models/component-common';
import { customInstance } from '../../config';

/**
 * Returns program templates available for organisation
In case organisation_id is not explicitly provided in the query it will be derived from caller identity
All templates will be prepopulated with organisation id where applicable

 */
export const programTemplatesGet = (
  params?: ProgramTemplatesGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramTemplatesGetResponse>({
    url: `/web/v2/program_templates`,
    method: 'get',
    params,
    signal,
  });
};

export const getProgramTemplatesGetQueryKey = (
  params?: ProgramTemplatesGetParams,
) => [`/web/v2/program_templates`, ...(params ? [params] : [])];

export type ProgramTemplatesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programTemplatesGet>>
>;
export type ProgramTemplatesGetQueryError = unknown;

export const useProgramTemplatesGet = <
  TData = Awaited<ReturnType<typeof programTemplatesGet>>,
  TError = unknown,
>(
  params?: ProgramTemplatesGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programTemplatesGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getProgramTemplatesGetQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof programTemplatesGet>>
  > = ({ signal }) => programTemplatesGet(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof programTemplatesGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const programTemplateGet = (
  templateId: string,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramTemplate>({
    url: `/web/v2/program_templates/${templateId}`,
    method: 'get',
    signal,
  });
};

export const getProgramTemplateGetQueryKey = (templateId: string) => [
  `/web/v2/program_templates/${templateId}`,
];

export type ProgramTemplateGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programTemplateGet>>
>;
export type ProgramTemplateGetQueryError = unknown;

export const useProgramTemplateGet = <
  TData = Awaited<ReturnType<typeof programTemplateGet>>,
  TError = unknown,
>(
  templateId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programTemplateGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getProgramTemplateGetQueryKey(templateId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof programTemplateGet>>
  > = ({ signal }) => programTemplateGet(templateId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof programTemplateGet>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!templateId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Provisions an organisation specific program from provided template payload.
Payload should contain customized program configuration including service selection

 */
export const programTemplateProvision = (programTemplate: ProgramTemplate) => {
  return customInstance<ProgramWithMeta>({
    url: `/web/v2/program_templates/provision`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: programTemplate,
  });
};

export type ProgramTemplateProvisionMutationResult = NonNullable<
  Awaited<ReturnType<typeof programTemplateProvision>>
>;
export type ProgramTemplateProvisionMutationBody = ProgramTemplate;
export type ProgramTemplateProvisionMutationError =
  | Error
  | void
  | Error404NotFoundResponse;

export const useProgramTemplateProvision = <
  TError = Error | void | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programTemplateProvision>>,
    TError,
    { data: ProgramTemplate },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programTemplateProvision>>,
    { data: ProgramTemplate }
  > = (props) => {
    const { data } = props ?? {};

    return programTemplateProvision(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof programTemplateProvision>>,
    TError,
    { data: ProgramTemplate },
    TContext
  >(mutationFn, mutationOptions);
};
export const programsGet = (
  params?: ProgramsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramsGetResponse>({
    url: `/web/v2/programs`,
    method: 'get',
    params,
    signal,
  });
};

export const getProgramsGetQueryKey = (params?: ProgramsGetParams) => [
  `/web/v2/programs`,
  ...(params ? [params] : []),
];

export type ProgramsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programsGet>>
>;
export type ProgramsGetQueryError = unknown;

export const useProgramsGet = <
  TData = Awaited<ReturnType<typeof programsGet>>,
  TError = unknown,
>(
  params?: ProgramsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProgramsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof programsGet>>> = ({
    signal,
  }) => programsGet(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof programsGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const programUpdate = (programId: string, program: Program) => {
  return customInstance<Program>({
    url: `/web/v2/programs/${programId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: program,
  });
};

export type ProgramUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programUpdate>>
>;
export type ProgramUpdateMutationBody = Program;
export type ProgramUpdateMutationError = unknown;

export const useProgramUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programUpdate>>,
    TError,
    { programId: string; data: Program },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programUpdate>>,
    { programId: string; data: Program }
  > = (props) => {
    const { programId, data } = props ?? {};

    return programUpdate(programId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof programUpdate>>,
    TError,
    { programId: string; data: Program },
    TContext
  >(mutationFn, mutationOptions);
};
export const programGet = (programId: string, signal?: AbortSignal) => {
  return customInstance<ProgramWithMeta>({
    url: `/web/v2/programs/${programId}`,
    method: 'get',
    signal,
  });
};

export const getProgramGetQueryKey = (programId: string) => [
  `/web/v2/programs/${programId}`,
];

export type ProgramGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programGet>>
>;
export type ProgramGetQueryError = unknown;

export const useProgramGet = <
  TData = Awaited<ReturnType<typeof programGet>>,
  TError = unknown,
>(
  programId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProgramGetQueryKey(programId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof programGet>>> = ({
    signal,
  }) => programGet(programId, signal);

  const query = useQuery<Awaited<ReturnType<typeof programGet>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!programId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const programGroupsGet = (
  programId: string,
  params?: ProgramGroupsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramGroupGetResponse>({
    url: `/web/v2/programs/${programId}/groups`,
    method: 'get',
    params,
    signal,
  });
};

export const getProgramGroupsGetQueryKey = (
  programId: string,
  params?: ProgramGroupsGetParams,
) => [`/web/v2/programs/${programId}/groups`, ...(params ? [params] : [])];

export type ProgramGroupsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programGroupsGet>>
>;
export type ProgramGroupsGetQueryError = unknown;

export const useProgramGroupsGet = <
  TData = Awaited<ReturnType<typeof programGroupsGet>>,
  TError = unknown,
>(
  programId: string,
  params?: ProgramGroupsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programGroupsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getProgramGroupsGetQueryKey(programId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof programGroupsGet>>
  > = ({ signal }) => programGroupsGet(programId, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof programGroupsGet>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!programId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const programGroupsUpdate = (
  programId: string,
  programGroupsUpdateRequest: ProgramGroupsUpdateRequest,
) => {
  return customInstance<ProgramGroup>({
    url: `/web/v2/programs/${programId}/groups`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: programGroupsUpdateRequest,
  });
};

export type ProgramGroupsUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programGroupsUpdate>>
>;
export type ProgramGroupsUpdateMutationBody = ProgramGroupsUpdateRequest;
export type ProgramGroupsUpdateMutationError = unknown;

export const useProgramGroupsUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupsUpdate>>,
    TError,
    { programId: string; data: ProgramGroupsUpdateRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programGroupsUpdate>>,
    { programId: string; data: ProgramGroupsUpdateRequest }
  > = (props) => {
    const { programId, data } = props ?? {};

    return programGroupsUpdate(programId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof programGroupsUpdate>>,
    TError,
    { programId: string; data: ProgramGroupsUpdateRequest },
    TContext
  >(mutationFn, mutationOptions);
};
export const programGroupGet = (
  programId: string,
  groupId: string,
  params?: ProgramGroupGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramGroupWithMeta>({
    url: `/web/v2/programs/${programId}/groups/${groupId}`,
    method: 'get',
    params,
    signal,
  });
};

export const getProgramGroupGetQueryKey = (
  programId: string,
  groupId: string,
  params?: ProgramGroupGetParams,
) => [
  `/web/v2/programs/${programId}/groups/${groupId}`,
  ...(params ? [params] : []),
];

export type ProgramGroupGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programGroupGet>>
>;
export type ProgramGroupGetQueryError = unknown;

export const useProgramGroupGet = <
  TData = Awaited<ReturnType<typeof programGroupGet>>,
  TError = unknown,
>(
  programId: string,
  groupId: string,
  params?: ProgramGroupGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programGroupGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getProgramGroupGetQueryKey(programId, groupId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof programGroupGet>>> = ({
    signal,
  }) => programGroupGet(programId, groupId, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof programGroupGet>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(programId && groupId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const programGroupUpdate = (
  programId: string,
  groupId: string,
  programGroupData: ProgramGroupData,
) => {
  return customInstance<ProgramGroup>({
    url: `/web/v2/programs/${programId}/groups/${groupId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: programGroupData,
  });
};

export type ProgramGroupUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programGroupUpdate>>
>;
export type ProgramGroupUpdateMutationBody = ProgramGroupData;
export type ProgramGroupUpdateMutationError = unknown;

export const useProgramGroupUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupUpdate>>,
    TError,
    { programId: string; groupId: string; data: ProgramGroupData },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programGroupUpdate>>,
    { programId: string; groupId: string; data: ProgramGroupData }
  > = (props) => {
    const { programId, groupId, data } = props ?? {};

    return programGroupUpdate(programId, groupId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof programGroupUpdate>>,
    TError,
    { programId: string; groupId: string; data: ProgramGroupData },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Performs budget simulation for a given limit

 */
export const programGroupLimitSimulation = (
  programId: string,
  groupId: string,
  programGroupLimit: ProgramGroupLimit,
  params?: ProgramGroupLimitSimulationParams,
) => {
  return customInstance<MembershipSummary>({
    url: `/web/v2/programs/${programId}/groups/${groupId}/limit/simulation`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: programGroupLimit,
    params,
  });
};

export type ProgramGroupLimitSimulationMutationResult = NonNullable<
  Awaited<ReturnType<typeof programGroupLimitSimulation>>
>;
export type ProgramGroupLimitSimulationMutationBody = ProgramGroupLimit;
export type ProgramGroupLimitSimulationMutationError = unknown;

export const useProgramGroupLimitSimulation = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupLimitSimulation>>,
    TError,
    {
      programId: string;
      groupId: string;
      data: ProgramGroupLimit;
      params?: ProgramGroupLimitSimulationParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programGroupLimitSimulation>>,
    {
      programId: string;
      groupId: string;
      data: ProgramGroupLimit;
      params?: ProgramGroupLimitSimulationParams;
    }
  > = (props) => {
    const { programId, groupId, data, params } = props ?? {};

    return programGroupLimitSimulation(programId, groupId, data, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof programGroupLimitSimulation>>,
    TError,
    {
      programId: string;
      groupId: string;
      data: ProgramGroupLimit;
      params?: ProgramGroupLimitSimulationParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Retrieves limit for given program and group.
Optionally it will return personalized limit if membership_id query is populated and personalized limit exists.
It will still return group level limit if no personalized limit available

 */
export const programGroupLimitGet = (
  programId: string,
  groupId: string,
  params?: ProgramGroupLimitGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramGroupLimitWithMeta>({
    url: `/web/v2/programs/${programId}/groups/${groupId}/limit`,
    method: 'get',
    params,
    signal,
  });
};

export const getProgramGroupLimitGetQueryKey = (
  programId: string,
  groupId: string,
  params?: ProgramGroupLimitGetParams,
) => [
  `/web/v2/programs/${programId}/groups/${groupId}/limit`,
  ...(params ? [params] : []),
];

export type ProgramGroupLimitGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programGroupLimitGet>>
>;
export type ProgramGroupLimitGetQueryError = unknown;

export const useProgramGroupLimitGet = <
  TData = Awaited<ReturnType<typeof programGroupLimitGet>>,
  TError = unknown,
>(
  programId: string,
  groupId: string,
  params?: ProgramGroupLimitGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programGroupLimitGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getProgramGroupLimitGetQueryKey(programId, groupId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof programGroupLimitGet>>
  > = ({ signal }) => programGroupLimitGet(programId, groupId, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof programGroupLimitGet>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(programId && groupId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Puts either a group level limit (membership_id query not populated)
or personalized limit (membership_id query populated).
Should be used for existing limits only - POST is being used to create a limit from scratch.
Optional date_from may be used to override default date budget is accrued from for particular limit

 */
export const programGroupLimitUpdate = (
  programId: string,
  groupId: string,
  programGroupLimit: ProgramGroupLimit,
  params?: ProgramGroupLimitUpdateParams,
) => {
  return customInstance<ProgramGroupLimit>({
    url: `/web/v2/programs/${programId}/groups/${groupId}/limit`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: programGroupLimit,
    params,
  });
};

export type ProgramGroupLimitUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programGroupLimitUpdate>>
>;
export type ProgramGroupLimitUpdateMutationBody = ProgramGroupLimit;
export type ProgramGroupLimitUpdateMutationError = unknown;

export const useProgramGroupLimitUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupLimitUpdate>>,
    TError,
    {
      programId: string;
      groupId: string;
      data: ProgramGroupLimit;
      params?: ProgramGroupLimitUpdateParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programGroupLimitUpdate>>,
    {
      programId: string;
      groupId: string;
      data: ProgramGroupLimit;
      params?: ProgramGroupLimitUpdateParams;
    }
  > = (props) => {
    const { programId, groupId, data, params } = props ?? {};

    return programGroupLimitUpdate(programId, groupId, data, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof programGroupLimitUpdate>>,
    TError,
    {
      programId: string;
      groupId: string;
      data: ProgramGroupLimit;
      params?: ProgramGroupLimitUpdateParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Creates either a group level limit (membership_id query not populated)
or personalized limit (membership_id query populated).
Should be used for new limits only - PUT is being used to update existing limits.
Optional date_from may be used to override default date budget is accrued from for particular limit

 */
export const programGroupLimitCreate = (
  programId: string,
  groupId: string,
  programGroupLimit: ProgramGroupLimit,
  params?: ProgramGroupLimitCreateParams,
) => {
  return customInstance<ProgramGroupLimit>({
    url: `/web/v2/programs/${programId}/groups/${groupId}/limit`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: programGroupLimit,
    params,
  });
};

export type ProgramGroupLimitCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programGroupLimitCreate>>
>;
export type ProgramGroupLimitCreateMutationBody = ProgramGroupLimit;
export type ProgramGroupLimitCreateMutationError =
  | Error400LimitInvalidPayloadResponse
  | Error403LimitForbiddenResponse
  | Error409LimitAlreadyExistsResponse;

export const useProgramGroupLimitCreate = <
  TError =
    | Error400LimitInvalidPayloadResponse
    | Error403LimitForbiddenResponse
    | Error409LimitAlreadyExistsResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupLimitCreate>>,
    TError,
    {
      programId: string;
      groupId: string;
      data: ProgramGroupLimit;
      params?: ProgramGroupLimitCreateParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programGroupLimitCreate>>,
    {
      programId: string;
      groupId: string;
      data: ProgramGroupLimit;
      params?: ProgramGroupLimitCreateParams;
    }
  > = (props) => {
    const { programId, groupId, data, params } = props ?? {};

    return programGroupLimitCreate(programId, groupId, data, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof programGroupLimitCreate>>,
    TError,
    {
      programId: string;
      groupId: string;
      data: ProgramGroupLimit;
      params?: ProgramGroupLimitCreateParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Deletes personalized limit

 */
export const programGroupLimitDelete = (
  programId: string,
  groupId: string,
  params: ProgramGroupLimitDeleteParams,
) => {
  return customInstance<void>({
    url: `/web/v2/programs/${programId}/groups/${groupId}/limit`,
    method: 'delete',
    params,
  });
};

export type ProgramGroupLimitDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof programGroupLimitDelete>>
>;

export type ProgramGroupLimitDeleteMutationError =
  | Error
  | Error404NotFoundResponse;

export const useProgramGroupLimitDelete = <
  TError = Error | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupLimitDelete>>,
    TError,
    {
      programId: string;
      groupId: string;
      params: ProgramGroupLimitDeleteParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programGroupLimitDelete>>,
    {
      programId: string;
      groupId: string;
      params: ProgramGroupLimitDeleteParams;
    }
  > = (props) => {
    const { programId, groupId, params } = props ?? {};

    return programGroupLimitDelete(programId, groupId, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof programGroupLimitDelete>>,
    TError,
    {
      programId: string;
      groupId: string;
      params: ProgramGroupLimitDeleteParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
export const programGroupLimitDisplayPut = (
  programGroupLimitId: string,
  modifyDisplayBudgetLimitRequest: ModifyDisplayBudgetLimitRequest,
  params: ProgramGroupLimitDisplayPutParams,
) => {
  return customInstance<ProgramGroupLimit>({
    url: `/web/v2/program-group-limits/${programGroupLimitId}`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: modifyDisplayBudgetLimitRequest,
    params,
  });
};

export type ProgramGroupLimitDisplayPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof programGroupLimitDisplayPut>>
>;
export type ProgramGroupLimitDisplayPutMutationBody =
  ModifyDisplayBudgetLimitRequest;
export type ProgramGroupLimitDisplayPutMutationError = unknown;

export const useProgramGroupLimitDisplayPut = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programGroupLimitDisplayPut>>,
    TError,
    {
      programGroupLimitId: string;
      data: ModifyDisplayBudgetLimitRequest;
      params: ProgramGroupLimitDisplayPutParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programGroupLimitDisplayPut>>,
    {
      programGroupLimitId: string;
      data: ModifyDisplayBudgetLimitRequest;
      params: ProgramGroupLimitDisplayPutParams;
    }
  > = (props) => {
    const { programGroupLimitId, data, params } = props ?? {};

    return programGroupLimitDisplayPut(programGroupLimitId, data, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof programGroupLimitDisplayPut>>,
    TError,
    {
      programGroupLimitId: string;
      data: ModifyDisplayBudgetLimitRequest;
      params: ProgramGroupLimitDisplayPutParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
export const programProductsGet = (
  programId: string,
  params?: ProgramProductsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProductsGetResponse>({
    url: `/web/v2/programs/${programId}/products`,
    method: 'get',
    params,
    signal,
  });
};

export const getProgramProductsGetQueryKey = (
  programId: string,
  params?: ProgramProductsGetParams,
) => [`/web/v2/programs/${programId}/products`, ...(params ? [params] : [])];

export type ProgramProductsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programProductsGet>>
>;
export type ProgramProductsGetQueryError = unknown;

export const useProgramProductsGet = <
  TData = Awaited<ReturnType<typeof programProductsGet>>,
  TError = unknown,
>(
  programId: string,
  params?: ProgramProductsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programProductsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getProgramProductsGetQueryKey(programId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof programProductsGet>>
  > = ({ signal }) => programProductsGet(programId, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof programProductsGet>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!programId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const programProductGet = (
  programId: string,
  productId: string,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramProduct>({
    url: `/web/v2/programs/${programId}/products/${productId}`,
    method: 'get',
    signal,
  });
};

export const getProgramProductGetQueryKey = (
  programId: string,
  productId: string,
) => [`/web/v2/programs/${programId}/products/${productId}`];

export type ProgramProductGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programProductGet>>
>;
export type ProgramProductGetQueryError = unknown;

export const useProgramProductGet = <
  TData = Awaited<ReturnType<typeof programProductGet>>,
  TError = unknown,
>(
  programId: string,
  productId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programProductGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getProgramProductGetQueryKey(programId, productId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof programProductGet>>
  > = ({ signal }) => programProductGet(programId, productId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof programProductGet>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(programId && productId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const programProductUpdate = (
  programId: string,
  productId: string,
  programProductConfiguration: ProgramProductConfiguration,
) => {
  return customInstance<ProgramProduct>({
    url: `/web/v2/programs/${programId}/products/${productId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: programProductConfiguration,
  });
};

export type ProgramProductUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programProductUpdate>>
>;
export type ProgramProductUpdateMutationBody = ProgramProductConfiguration;
export type ProgramProductUpdateMutationError = unknown;

export const useProgramProductUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programProductUpdate>>,
    TError,
    { programId: string; productId: string; data: ProgramProductConfiguration },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programProductUpdate>>,
    { programId: string; productId: string; data: ProgramProductConfiguration }
  > = (props) => {
    const { programId, productId, data } = props ?? {};

    return programProductUpdate(programId, productId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof programProductUpdate>>,
    TError,
    { programId: string; productId: string; data: ProgramProductConfiguration },
    TContext
  >(mutationFn, mutationOptions);
};
export const programServicesGet = (
  programId: string,
  params?: ProgramServicesGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<ProgramServicesGetResponse>({
    url: `/web/v2/programs/${programId}/services`,
    method: 'get',
    params,
    signal,
  });
};

export const getProgramServicesGetQueryKey = (
  programId: string,
  params?: ProgramServicesGetParams,
) => [`/web/v2/programs/${programId}/services`, ...(params ? [params] : [])];

export type ProgramServicesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof programServicesGet>>
>;
export type ProgramServicesGetQueryError = unknown;

export const useProgramServicesGet = <
  TData = Awaited<ReturnType<typeof programServicesGet>>,
  TError = unknown,
>(
  programId: string,
  params?: ProgramServicesGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof programServicesGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getProgramServicesGetQueryKey(programId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof programServicesGet>>
  > = ({ signal }) => programServicesGet(programId, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof programServicesGet>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!programId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const programServicesUpdate = (
  programId: string,
  programServicesUpdateRequest: ProgramServicesUpdateRequest,
) => {
  return customInstance<ProgramServicesUpdateResponse>({
    url: `/web/v2/programs/${programId}/services`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: programServicesUpdateRequest,
  });
};

export type ProgramServicesUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programServicesUpdate>>
>;
export type ProgramServicesUpdateMutationBody = ProgramServicesUpdateRequest;
export type ProgramServicesUpdateMutationError = unknown;

export const useProgramServicesUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programServicesUpdate>>,
    TError,
    { programId: string; data: ProgramServicesUpdateRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programServicesUpdate>>,
    { programId: string; data: ProgramServicesUpdateRequest }
  > = (props) => {
    const { programId, data } = props ?? {};

    return programServicesUpdate(programId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof programServicesUpdate>>,
    TError,
    { programId: string; data: ProgramServicesUpdateRequest },
    TContext
  >(mutationFn, mutationOptions);
};
export const programServiceUpdate = (
  programId: string,
  serviceId: string,
  programServiceUpdateRequest: ProgramServiceUpdateRequest,
) => {
  return customInstance<void>({
    url: `/web/v2/programs/${programId}/services/${serviceId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: programServiceUpdateRequest,
  });
};

export type ProgramServiceUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programServiceUpdate>>
>;
export type ProgramServiceUpdateMutationBody = ProgramServiceUpdateRequest;
export type ProgramServiceUpdateMutationError = Error;

export const useProgramServiceUpdate = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programServiceUpdate>>,
    TError,
    { programId: string; serviceId: string; data: ProgramServiceUpdateRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programServiceUpdate>>,
    { programId: string; serviceId: string; data: ProgramServiceUpdateRequest }
  > = (props) => {
    const { programId, serviceId, data } = props ?? {};

    return programServiceUpdate(programId, serviceId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof programServiceUpdate>>,
    TError,
    { programId: string; serviceId: string; data: ProgramServiceUpdateRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Changes the status of the program to INACTIVE
and removes related program_groups and program_services.

 */
export const programDeactivate = (programId: string) => {
  return customInstance<void>({
    url: `/web/v2/programs/${programId}/deactivate`,
    method: 'post',
  });
};

export type ProgramDeactivateMutationResult = NonNullable<
  Awaited<ReturnType<typeof programDeactivate>>
>;

export type ProgramDeactivateMutationError = Error | Error404NotFoundResponse;

export const useProgramDeactivate = <
  TError = Error | Error404NotFoundResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof programDeactivate>>,
    TError,
    { programId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof programDeactivate>>,
    { programId: string }
  > = (props) => {
    const { programId } = props ?? {};

    return programDeactivate(programId);
  };

  return useMutation<
    Awaited<ReturnType<typeof programDeactivate>>,
    TError,
    { programId: string },
    TContext
  >(mutationFn, mutationOptions);
};
