/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  RegistrationsGetResponse,
  RegistrationsGetParams,
  Registration,
  RegistrationCreateRequest,
  Address,
} from '../../models';
import { customInstance } from '../../config';

export const registrationsGet = (
  params?: RegistrationsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<RegistrationsGetResponse>({
    url: `/web/v2/registrations`,
    method: 'get',
    params,
    signal,
  });
};

export const getRegistrationsGetQueryKey = (
  params?: RegistrationsGetParams,
) => [`/web/v2/registrations`, ...(params ? [params] : [])];

export type RegistrationsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof registrationsGet>>
>;
export type RegistrationsGetQueryError = unknown;

export const useRegistrationsGet = <
  TData = Awaited<ReturnType<typeof registrationsGet>>,
  TError = unknown,
>(
  params?: RegistrationsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof registrationsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRegistrationsGetQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof registrationsGet>>
  > = ({ signal }) => registrationsGet(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof registrationsGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const registrationsCreate = (
  registrationCreateRequest: RegistrationCreateRequest,
) => {
  return customInstance<Registration>({
    url: `/web/v2/registrations`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: registrationCreateRequest,
  });
};

export type RegistrationsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof registrationsCreate>>
>;
export type RegistrationsCreateMutationBody = RegistrationCreateRequest;
export type RegistrationsCreateMutationError = unknown;

export const useRegistrationsCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationsCreate>>,
    TError,
    { data: RegistrationCreateRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registrationsCreate>>,
    { data: RegistrationCreateRequest }
  > = (props) => {
    const { data } = props ?? {};

    return registrationsCreate(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof registrationsCreate>>,
    TError,
    { data: RegistrationCreateRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * This call will be proxied to `PUT /web/memberships/{membership_id}/registration`. Please check that call for more info.
 */
export const registrationUpdate = (
  membershipId: string,
  registration: Registration,
) => {
  return customInstance<Registration>({
    url: `/web/v2/registrations/${membershipId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: registration,
  });
};

export type RegistrationUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof registrationUpdate>>
>;
export type RegistrationUpdateMutationBody = Registration;
export type RegistrationUpdateMutationError = unknown;

export const useRegistrationUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationUpdate>>,
    TError,
    { membershipId: string; data: Registration },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registrationUpdate>>,
    { membershipId: string; data: Registration }
  > = (props) => {
    const { membershipId, data } = props ?? {};

    return registrationUpdate(membershipId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof registrationUpdate>>,
    TError,
    { membershipId: string; data: Registration },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * This call will disable the account of an employee permanently Following actions will be performed: - Disable his card - Disable provider credentials such as qpark - block budgets and remove payment profiles
 */
export const registrationDelete = (membershipId: string) => {
  return customInstance<void>({
    url: `/web/v2/registrations/${membershipId}`,
    method: 'delete',
  });
};

export type RegistrationDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof registrationDelete>>
>;

export type RegistrationDeleteMutationError = unknown;

export const useRegistrationDelete = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationDelete>>,
    TError,
    { membershipId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registrationDelete>>,
    { membershipId: string }
  > = (props) => {
    const { membershipId } = props ?? {};

    return registrationDelete(membershipId);
  };

  return useMutation<
    Awaited<ReturnType<typeof registrationDelete>>,
    TError,
    { membershipId: string },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Update an address of a registration

 */
export const registrationAddressUpdate = (
  membershipId: string,
  address: Address,
) => {
  return customInstance<Registration>({
    url: `/web/v2/registrations/${membershipId}/address`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: address,
  });
};

export type RegistrationAddressUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof registrationAddressUpdate>>
>;
export type RegistrationAddressUpdateMutationBody = Address;
export type RegistrationAddressUpdateMutationError = unknown;

export const useRegistrationAddressUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registrationAddressUpdate>>,
    TError,
    { membershipId: string; data: Address },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registrationAddressUpdate>>,
    { membershipId: string; data: Address }
  > = (props) => {
    const { membershipId, data } = props ?? {};

    return registrationAddressUpdate(membershipId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof registrationAddressUpdate>>,
    TError,
    { membershipId: string; data: Address },
    TContext
  >(mutationFn, mutationOptions);
};
