/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Resource } from './resource';
import type { Operation } from './operation';

export interface NotificationBody {
  resource?: Resource;
  resource_id?: string;
  operation?: Operation;
}
