/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { PaymentMethodParam } from './paymentMethodParam';

/**
 * Repeat "payment_method" param multiple times to include multiple payment methods
 */
export type QueryPaymentMethodsParameter = PaymentMethodParam[];
