/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { QueryProgramIdParameter } from './component-parameters/queryProgramIdParameter';

export type MembershipSummaryRecalculateParams = {
  program_id?: QueryProgramIdParameter;
};
