/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { QueryPaymentMethodsParameter } from './queryPaymentMethodsParameter';
import type { QueryProgramIdParameter } from './component-parameters/queryProgramIdParameter';
import type { QueryServiceIdParameter } from './component-parameters/queryServiceIdParameter';
import type { QueryProviderIdParameter } from './component-parameters/queryProviderIdParameter';

export type MembershipProductsGetParams = {
  payment_method?: QueryPaymentMethodsParameter;
  program_id?: QueryProgramIdParameter;
  service_id?: QueryServiceIdParameter;
  provider_id?: QueryProviderIdParameter;
};
