import { Button } from '@chakra-ui/button';
import { Box, Text } from '@chakra-ui/layout';
import {
  useCardConvertToPhysical,
  cardsGet,
  getCardsGetQueryKey,
} from '@libs/api-v2/endpoints';
import { Card } from '@libs/api/models';
import { QueryStatus } from '@libs/core/constants';
import { i18nKeys } from '@libs/core/i18n/dashboard-core';
import { Drawer } from '@libs/ui/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { QueryStatusText } from '../../../components/query-status-text';

type ConvertPhysicalCardDrawerProps = {
  card: Card;
  isOpen: boolean;
  onClose: () => void;
};

export const ConvertPhysicalCardDrawer = ({
  card,
  isOpen,
  onClose,
}: ConvertPhysicalCardDrawerProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();

  const { mutateAsync: convertPhysical, status } = useCardConvertToPhysical({
    mutation: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        setError(error.translation?.key || error.detail);
      },
      onSuccess: () => {
        const params = { membership_id: card.membership_id };
        const queryKey = getCardsGetQueryKey(params);
        queryClient.invalidateQueries(queryKey);
        queryClient.fetchQuery(queryKey, () => cardsGet(params));
        setError(null);
      },
    },
  });

  const handleConvert = () => {
    const randomPinCode = Math.floor(1000 + Math.random() * 9000).toString();
    convertPhysical({ cardId: card.id, data: { pin_code: randomPinCode } });
  };

  const statusText = {
    [QueryStatus.loading]: t(i18nKeys.operator.cards.conversion.status.loading),
    [QueryStatus.error]: t(i18nKeys.operator.cards.conversion.status.error),
    [QueryStatus.success]: t(i18nKeys.operator.cards.conversion.status.success),
  };

  return (
    <Drawer
      title={t(i18nKeys.operator.cards.conversion.title)}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Drawer.Body>
        <Box>
          <Text size="Body2" color="ink.dark" mt="2rem" mb="4rem">
            {t(i18nKeys.operator.cards.conversion.explanation)}
          </Text>
          <Button
            variant="primary"
            size="body1"
            onClick={handleConvert}
            disabled={card.is_physical}
            isLoading={status === QueryStatus.loading}
            loadingText={t(i18nKeys.operator.cards.conversion.status.idle)}
          >
            {t(i18nKeys.operator.cards.conversion.status.idle)}
          </Button>

          <QueryStatusText
            error={error}
            status={status}
            statusText={statusText}
          />
        </Box>
      </Drawer.Body>
    </Drawer>
  );
};
