/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { PaymentMethod } from './paymentMethod';
import type { Meta } from './meta';

export interface PaymentMethodGetResponse {
  data?: PaymentMethod[];
  meta?: Meta;
}
