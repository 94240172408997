/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { BudgetDistribution } from './budgetDistribution';
import type { ExpenseRuleExecutionReport } from './expenseRuleExecutionReport';

export interface ExpenseRule {
  id?: Uuid;
  /** The starting date from which the expense rule to start to be executed, creating expenses (repeated according with the interval attribute). */
  start_date?: string;
  /** The date on which the expense rule will stop being executed (no more expenses will be created). */
  end_date?: string;
  interval?: BudgetDistribution;
  membership_id?: Uuid;
  expense_total_amount?: number;
  expense_employer_amount?: number;
  expense_distance?: number;
  expense_program_id?: Uuid;
  expense_service_id?: Uuid;
  expense_product_id?: Uuid;
  expense_provider_id?: Uuid;
  expense_title?: string;
  expense_expense_code?: string;
  expense_attachment_url?: string;
  is_archived?: boolean;
  last_execution_status?: string;
  last_execution_at?: string;
  last_execution_report?: ExpenseRuleExecutionReport;
}
