/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export interface LimitMeta {
  /** Indicates number of employees impacted by program group limit
   */
  impacted_employees_count?: number;
}
