import { Button } from '@chakra-ui/button';
import { Box, SimpleGrid, Stack, Text } from '@chakra-ui/layout';
import { Radio } from '@chakra-ui/radio';
import { ResponsiveValue } from '@chakra-ui/styled-system';
import { useTheme } from '@chakra-ui/system';
import {
  // useOrganisationGet,
  useRegistrationUpdate,
} from '@libs/api-v2/endpoints';
import { useMembershipRegistrationPatch } from '@libs/api-v2/endpoints';
import {
  Gender,
  SocialSecretarySocialSecretaryEmployeeType,
  SocialSecretarySocialSecretaryName,
} from '@libs/api/models';
import { FORMAT_DATE, INPUT_TYPES } from '@libs/core/constants';
import { i18nCountryKeys, i18nKeys } from '@libs/core/i18n/dashboard-core';
import {
  Drawer,
  InputField,
  RadioGroupField,
  SelectField,
  useToast,
} from '@libs/ui/components';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { EmployeeFormInputsProps } from '../../employee.types';
import * as Yup from 'yup';
import { validateSchema } from '@libs/core/utils/validation';
import { Flex, Tag } from '@chakra-ui/react';
import { getRegistrationGetQueryKey } from '@libs/api/endpoints/memberships/memberships';
import { OrganisationAllOfOrganisationScope } from '@libs/api/models/organisationAllOfOrganisationScope';
import { useOrganisationGet } from '@libs/api/endpoints';
import { Registration } from '@libs/api-v2/models';

type EmployeeDetailsProps = {
  //Change
  employee: Registration;
  isOpen: boolean;
  onClose: () => void;
  refetch?: () => void;
};
export const validationEmployeeSchema = Yup.object({
  phone_number: Yup.string().required().phoneNumber(),
});
export const EmployeeDetailsDrawer = ({
  employee,
  isOpen,
  onClose,
  refetch,
}: EmployeeDetailsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { data: organisation } = useOrganisationGet(employee.organisation_id);

  const { mutateAsync: submit, isLoading } = useRegistrationUpdate({
    mutation: {
      onSuccess: () => {
        // update employee data
        const queryKey = getRegistrationGetQueryKey(employee.membership_id);
        queryClient.invalidateQueries(queryKey);
        queryClient.fetchQuery(queryKey);
        refetch();
        onClose();
        toast({
          title: t(
            i18nKeys.operator.employees.details.update_employee_success.title,
          ),
          content: t(
            i18nKeys.operator.employees.details.update_employee_success.content,
          ),
        });
      },
    },
  });

  const { mutateAsync: patchRegistration, isLoading: isLoadingPatch } =
    useMembershipRegistrationPatch({
      mutation: {
        onSuccess: () => {
          // update employee data
          const queryKey = getRegistrationGetQueryKey(employee.membership_id);
          queryClient.invalidateQueries(queryKey);
          queryClient.fetchQuery(queryKey);
          toast({
            title: t(
              i18nKeys.operator.employees.details.update_employee_success.title,
            ),
            content: t(
              i18nKeys.operator.employees.details.update_employee_success
                .content,
            ),
          });
        },
      },
    });

  const submitHandler = (values: Registration) => {
    if (values.phone_number !== employee.phone_number) {
      patchRegistration({
        membershipId: employee.membership_id,
        data: { phone_number: values.phone_number },
      });
    } else {
      submit({
        membershipId: employee.membership_id,
        data: { ...employee, ...values },
      });
    }
  };

  const detailsInputs: {
    columns: ResponsiveValue<number>;
    inputs: EmployeeFormInputsProps[];
    title?: string;
  }[] = [
    {
      columns: { sm: 1, md: 2 },
      inputs: [
        {
          name: 'first_name',
          value: employee.first_name,
          label: t(i18nKeys.common.firstname),
          isDisabled: false,
        },
        {
          name: 'email',
          value: employee.email,
          isDisabled:
            organisation?.organisation_scope ===
            OrganisationAllOfOrganisationScope.OLDGEN,
        },
        {
          name: 'date_of_birth',
          value:
            employee.date_of_birth &&
            dayjs(employee.date_of_birth).format(FORMAT_DATE),
        },
        { name: 'nationality', value: employee.nationality },
        {
          name: 'last_name',
          value: employee.last_name,
          label: t(i18nKeys.common.lastname),
          isDisabled: false,
        },
        {
          name: 'phone_number',
          value: employee.phone_number,
          isDisabled: false,
        },
        {
          name: 'country_of_birth',
          value: employee.country_of_birth,
          label: t(i18nKeys.common.country_of_birth),
        },
        { name: 'place_of_birth', value: employee.place_of_birth },
      ],
    },
    {
      columns: { sm: 1, md: 2 },
      title: t(i18nKeys.common.address),
      inputs: [
        {
          name: 'address.street',
          value: employee.address?.street,
          label: t(i18nKeys.common.street),
          isDisabled: false,
        },
        {
          name: 'address.city',
          value: employee.address?.city,
          label: t(i18nKeys.common.city),
          isDisabled: false,
        },
        {
          name: 'address.postal_code',
          value: employee.address?.postal_code,
          label: t(i18nKeys.common.postal_code),
          isDisabled: false,
        },
        {
          name: 'address.country_code',
          type: INPUT_TYPES.select,
          options: Object.values(i18nCountryKeys),
          value: employee.address?.country_code,
          label: t(i18nKeys.common.country_code),
          isDisabled: false,
        },
      ],
    },
    {
      columns: { sm: 1, md: 2 },
      title: t(i18nKeys.operator.employees.details.work_related_title),
      inputs: [
        {
          name: 'start_on',
          value:
            employee.start_on && dayjs(employee.start_on).format(FORMAT_DATE),
          label: t(i18nKeys.common.start_at),
        },
      ],
    },
    {
      columns: { sm: 1, md: 2 },
      title: t(i18nKeys.operator.employees.details.social_secretary),
      inputs: [
        {
          name: 'social_secretary.social_secretary_name',
          type: 'select',
          options: Object.values(SocialSecretarySocialSecretaryName),
          value: employee.social_secretary?.social_secretary_name,
          isDisabled: false,
          label: t(i18nKeys.common.social_secretary_name),
        },
        {
          name: 'social_secretary.social_secretary_employee_reference',
          value: employee.social_secretary?.social_secretary_employee_reference,
          isDisabled: false,
          label: t(i18nKeys.common.social_secretary_employee_reference),
        },
        {
          name: 'social_secretary.social_secretary_employee_type',
          type: INPUT_TYPES.select,
          options: Object.values(SocialSecretarySocialSecretaryEmployeeType),
          optionsI18nKey: i18nKeys.common.SocialSecretaryEmployeeType,
          value: employee.social_secretary?.social_secretary_employee_type,
          isDisabled: false,
          label: t(i18nKeys.common.social_secretary_employee_type),
        },
      ],
    },
    {
      columns: { sm: 1, md: 2 },
      title: t(i18nKeys.operator.employees.details.other_title),
      inputs: [
        {
          name: 'internal_payroll_id',
          value: employee.internal_payroll_id,
          isDisabled: false,
        },
      ],
    },
  ];

  return (
    <Drawer
      title={t(i18nKeys.operator.employees.details.title)}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <h3>Internal keys</h3>
      <Flex>
        <Tag size={'md'} key={employee.user_id} width="auto">
          {' '}
          Id : {employee.user_id}{' '}
        </Tag>
      </Flex>
      <Flex>
        <Tag size={'md'} key={employee.user_id} width="auto">
          {' '}
          MembershipId : {employee.membership_id}{' '}
        </Tag>
      </Flex>
      <Flex>
        <Tag size={'md'} key={employee.membership_id} width="auto">
          {' '}
          OrganisationId : {employee.organisation_id}{' '}
        </Tag>
      </Flex>
      <Flex>
        <Tag size={'md'} key={employee.membership_id} width="auto">
          {' '}
          TreezorReference : {employee.bank_reference}{' '}
        </Tag>
      </Flex>
      <Drawer.Body overflowX="hidden">
        <Text size="Body1" color="ink.dark" my="2rem">
          {t(i18nKeys.operator.employees.details.introduction)}
        </Text>

        <Formik<Registration>
          initialValues={{
            gender: employee.gender,
            first_name: employee.first_name,
            email: employee.email,
            date_of_birth: employee.date_of_birth,
            nationality: employee.nationality,
            last_name: employee.last_name,
            phone_number: employee.phone_number,
            country_of_birth: employee.country_of_birth,
            place_of_birth: employee.place_of_birth,
            address: {
              city: employee.address?.city,
              postal_code: employee.address?.postal_code,
              country_code: employee.address?.country_code,
              street: employee.address?.street,
              latitude: employee?.address?.latitude,
              longitude: employee?.address?.longitude,
            },
            start_on: employee.start_on,
            internal_payroll_id: employee.internal_payroll_id,
            social_secretary: {
              social_secretary_name:
                employee?.social_secretary?.social_secretary_name,
              social_secretary_employee_reference:
                employee.social_secretary?.social_secretary_employee_reference,
              social_secretary_employee_type:
                employee?.social_secretary?.social_secretary_employee_type,
            },
          }}
          validate={validateSchema(validationEmployeeSchema)}
          onSubmit={submitHandler}
        >
          <Form>
            <Stack>
              <RadioGroupField ml="2rem" name="gender" isRequired>
                <Stack direction="row" spacing="1rem">
                  <Radio isDisabled value={Gender.Male}>
                    {t(i18nKeys.common.mr)}
                  </Radio>
                  <Radio isDisabled value={Gender.Female}>
                    {t(i18nKeys.common.mrs)}
                  </Radio>
                </Stack>
              </RadioGroupField>
              {detailsInputs.map(({ title, columns, inputs }, index) => (
                <Box key={title || index}>
                  <Text
                    fontSize="body2"
                    fontFamily={theme.fonts.large}
                    ml="2rem"
                    color="ink.darker"
                  >
                    {title}
                  </Text>
                  <SimpleGrid
                    columns={columns}
                    spacing="1rem"
                    p="0 2rem"
                    mb="2rem"
                  >
                    {inputs.map(
                      ({
                        name,
                        label,
                        isDisabled = true,
                        type,
                        options,
                        optionsI18nKey,
                      }: EmployeeFormInputsProps) =>
                        type === INPUT_TYPES.select ? (
                          <SelectField
                            key={name}
                            name={name}
                            isDisabled={isDisabled}
                            label={label || t(i18nKeys.common[name])}
                          >
                            {options.map((option) => (
                              <option key={option} value={option}>
                                {optionsI18nKey
                                  ? t(optionsI18nKey[option])
                                  : option}
                              </option>
                            ))}
                          </SelectField>
                        ) : (
                          <InputField
                            key={name}
                            name={name}
                            isDisabled={isDisabled}
                            label={label || t(i18nKeys.common[name])}
                          />
                        ),
                    )}
                  </SimpleGrid>
                </Box>
              ))}
              <Button
                type="submit"
                alignSelf="flex-end"
                variant="primary"
                size="body1"
                disabled={isLoading || isLoadingPatch}
                isLoading={isLoading || isLoadingPatch}
                loadingText={t(i18nKeys.common.submit)}
              >
                {t(i18nKeys.common.submit)}
              </Button>
            </Stack>
          </Form>
        </Formik>
      </Drawer.Body>
    </Drawer>
  );
};
