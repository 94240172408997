/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Phone } from './component-common/phone';
import type { CountryCode } from './component-common/countryCode';

export interface RegistrationPatch {
  phone_number: Phone;
  phone_number_country_code?: CountryCode;
}
