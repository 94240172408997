/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { BudgetDistribution } from './budgetDistribution';

export interface SpendingPolicy {
  limit_amount: number;
  carry_over: boolean;
  prorata: boolean;
  budget_distribution: BudgetDistribution;
  display_budget_limit: boolean;
}
