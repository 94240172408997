/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { MembershipRole } from './membershipRole';

export interface AddMembershipRole {
  membership_id: Uuid;
  role: MembershipRole;
}
