/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useMutation } from '@tanstack/react-query';
import type {
  UseMutationOptions,
  MutationFunction,
} from '@tanstack/react-query';
import type { Document, DocumentCreateBody } from '../../models';
import { customInstance } from '../../config';

export const documentCreate = (documentCreateBody: DocumentCreateBody) => {
  return customInstance<Document>({
    url: `/web/v2/documents`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: documentCreateBody,
  });
};

export type DocumentCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof documentCreate>>
>;
export type DocumentCreateMutationBody = DocumentCreateBody;
export type DocumentCreateMutationError = unknown;

export const useDocumentCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof documentCreate>>,
    TError,
    { data: DocumentCreateBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof documentCreate>>,
    { data: DocumentCreateBody }
  > = (props) => {
    const { data } = props ?? {};

    return documentCreate(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof documentCreate>>,
    TError,
    { data: DocumentCreateBody },
    TContext
  >(mutationFn, mutationOptions);
};
