/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ExpenseInterface =
  typeof ExpenseInterface[keyof typeof ExpenseInterface];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseInterface = {
  APP: 'APP',
  CARD: 'CARD',
  MANUAL: 'MANUAL',
} as const;
