/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Wallet } from './wallet';
import type { Meta } from './meta';

export interface WalletGetResponse {
  data?: Wallet[];
  meta?: Meta;
}
