/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { ServiceNameKey } from './serviceNameKey';
import type { ServiceCategoryNameKey } from './serviceCategoryNameKey';
import type { PaymentMethods } from './paymentMethods';

export interface Service {
  id?: Uuid;
  /** translation key for the service name */
  name_key?: ServiceNameKey;
  category_name_key?: ServiceCategoryNameKey;
  icon_url?: string;
  payment_methods?: PaymentMethods;
}
