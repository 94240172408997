/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

/**
 * Indicates actions required to finalize employee onboarding
 */
export interface OnboardingActions {
  /** Indicates whether employee's phone number had been setup */
  readonly is_phone_number_populated: boolean;
  /** Indicates whether the employee has signed all latest sworn statements of the programs he is part of */
  readonly needs_to_sign_sworn_statements: boolean;
}
