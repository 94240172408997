/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type TripType = typeof TripType[keyof typeof TripType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TripType = {
  COMPLETE: 'COMPLETE',
  PARTIAL: 'PARTIAL',
  COMPLETE_AND_PARTIAL: 'COMPLETE_AND_PARTIAL',
  NONE: 'NONE',
} as const;
