/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { QueryOrganisationIdParameter } from './component-parameters/queryOrganisationIdParameter';
import type { QueryReportAggregationParameter } from './queryReportAggregationParameter';
import type { QueryReportTimeScaleParameter } from './queryReportTimeScaleParameter';
import type { QueryReportDateFromParameter } from './queryReportDateFromParameter';
import type { QueryReportDateToParameter } from './queryReportDateToParameter';

export type ReportGetReportCo2SummaryDataParams = {
  organisation_id?: QueryOrganisationIdParameter;
  aggregation?: QueryReportAggregationParameter;
  time_scale?: QueryReportTimeScaleParameter;
  range_from?: QueryReportDateFromParameter;
  range_to?: QueryReportDateToParameter;
};
