/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  InvitationsWithMeta,
  InvitationsGetParams,
  ResendInvitationRequest,
} from '../../models';
import { customInstance } from '../../config';

export const invitationsGet = (
  params?: InvitationsGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<InvitationsWithMeta>({
    url: `/web/v2/invitations`,
    method: 'get',
    params,
    signal,
  });
};

export const getInvitationsGetQueryKey = (params?: InvitationsGetParams) => [
  `/web/v2/invitations`,
  ...(params ? [params] : []),
];

export type InvitationsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof invitationsGet>>
>;
export type InvitationsGetQueryError = unknown;

export const useInvitationsGet = <
  TData = Awaited<ReturnType<typeof invitationsGet>>,
  TError = unknown,
>(
  params?: InvitationsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof invitationsGet>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInvitationsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof invitationsGet>>> = ({
    signal,
  }) => invitationsGet(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof invitationsGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * This endpoint will send new employee invitation email

 */
export const resendInvitation = (
  resendInvitationRequest: ResendInvitationRequest,
) => {
  return customInstance<void>({
    url: `/web/v2/invitations/resend-invitation`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: resendInvitationRequest,
  });
};

export type ResendInvitationMutationResult = NonNullable<
  Awaited<ReturnType<typeof resendInvitation>>
>;
export type ResendInvitationMutationBody = ResendInvitationRequest;
export type ResendInvitationMutationError = unknown;

export const useResendInvitation = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resendInvitation>>,
    TError,
    { data: ResendInvitationRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resendInvitation>>,
    { data: ResendInvitationRequest }
  > = (props) => {
    const { data } = props ?? {};

    return resendInvitation(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof resendInvitation>>,
    TError,
    { data: ResendInvitationRequest },
    TContext
  >(mutationFn, mutationOptions);
};
