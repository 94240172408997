/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { TripType } from './tripType';

export type TripCreateRequestTripConfiguration = {
  trip_type_to?: TripType;
  trip_type_back?: TripType;
};
