/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export interface GroupData {
  name: string;
  description?: string;
  /** If true, this is the organisations' default group. */
  default_group?: boolean;
}
