/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ActivationStatus =
  typeof ActivationStatus[keyof typeof ActivationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActivationStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const;
