/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type OperatingSystem =
  typeof OperatingSystem[keyof typeof OperatingSystem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatingSystem = {
  Android: 'Android',
  iOS: 'iOS',
} as const;
