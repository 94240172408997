/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { CountryCode } from './component-common/countryCode';

export interface Address {
  street: string;
  postal_code: string;
  city: string;
  country_code: CountryCode;
  latitude: number;
  longitude: number;
}
