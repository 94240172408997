/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type InvitationStatus =
  typeof InvitationStatus[keyof typeof InvitationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvitationStatus = {
  SCHEDULED: 'SCHEDULED',
  INVITED: 'INVITED',
} as const;
