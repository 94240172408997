/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { QueryProgramIdsParameter } from './component-parameters/queryProgramIdsParameter';
import type { QueryMembershipIdsParameter } from './component-parameters/queryMembershipIdsParameter';
import type { QueryServiceIdsParameter } from './component-parameters/queryServiceIdsParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';
import type { QuerySortDefinitionParameter } from './component-parameters/querySortDefinitionParameter';
import type { QueryDateFromParameter } from './component-parameters/queryDateFromParameter';
import type { QueryDateToParameter } from './component-parameters/queryDateToParameter';
import type { QueryOnlyTeamsParameter } from './component-parameters/queryOnlyTeamsParameter';
import type { QueryCategoryNameKeyParameter } from './component-parameters/queryCategoryNameKeyParameter';
import type { ReviewStatusParameter } from './reviewStatusParameter';
import type { RefundStatusParameter } from './refundStatusParameter';
import type { RefundIssuerParameter } from './refundIssuerParameter';
import type { SearchEmployeeNameParameter } from './searchEmployeeNameParameter';
import type { QueryExpenseTypeParameter } from './queryExpenseTypeParameter';

export type ExpenseListParams = {
  program_ids?: QueryProgramIdsParameter;
  membership_ids?: QueryMembershipIdsParameter;
  service_ids?: QueryServiceIdsParameter;
  page_number?: QueryPageNumberParameter;
  page_size?: QueryPageSizeParameter;
  sort?: QuerySortDefinitionParameter;
  date_from?: QueryDateFromParameter;
  range_to?: QueryDateToParameter;
  only_teams?: QueryOnlyTeamsParameter;
  category_name_key?: QueryCategoryNameKeyParameter;
  review_status?: ReviewStatusParameter;
  refund_status?: RefundStatusParameter;
  refund_issuer?: RefundIssuerParameter;
  search_employee_name?: SearchEmployeeNameParameter;
  expense_type?: QueryExpenseTypeParameter;
};
