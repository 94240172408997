/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { QueryIncludesMetaParameter } from './component-parameters/queryIncludesMetaParameter';
import type { QueryMembershipIdParameter } from './component-parameters/queryMembershipIdParameter';

export type ProgramGroupLimitGetParams = {
  includes_meta?: QueryIncludesMetaParameter;
  membership_id?: QueryMembershipIdParameter;
};
