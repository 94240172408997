/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

/**
 * Only 1 restriction group can be applied at a time
 */
export interface CardUpdateRequest {
  mid_restriction_group_reference?: number;
  mcc_restriction_group_reference?: number;
}
