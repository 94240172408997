/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type QueryPolicyTypeParameter =
  typeof QueryPolicyTypeParameter[keyof typeof QueryPolicyTypeParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QueryPolicyTypeParameter = {
  EXPENSE: 'EXPENSE',
  REFUND: 'REFUND',
} as const;
