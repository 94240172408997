/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';
import type { QuerySortDefinitionParameter } from './component-parameters/querySortDefinitionParameter';
import type { QueryMembershipIdsParameter } from './component-parameters/queryMembershipIdsParameter';

export type ExpenseRulesListParams = {
  page_number?: QueryPageNumberParameter;
  page_size?: QueryPageSizeParameter;
  sort?: QuerySortDefinitionParameter;
  membership_ids?: QueryMembershipIdsParameter;
};
