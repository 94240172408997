/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type CardStatus = typeof CardStatus[keyof typeof CardStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardStatus = {
  UNLOCK: 'UNLOCK',
  LOCK: 'LOCK',
  LOST: 'LOST',
  STOLEN: 'STOLEN',
} as const;
