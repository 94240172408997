/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { SubscriptionStatus } from './subscriptionStatus';
import type { PaymentMethods } from './paymentMethods';

export interface MembershipProduct {
  readonly product_id?: string;
  readonly product_name?: string;
  readonly membership_id?: string;
  readonly program_id?: string;
  subscription_status?: SubscriptionStatus;
  payment_methods?: PaymentMethods;
}
