/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { AmountOfMoney } from './amountOfMoney';
import type { SplitPaymentStatus } from './splitPaymentStatus';

export interface SplitPayment {
  readonly id: Uuid;
  splittable_transaction_id: Uuid;
  expense_id?: Uuid;
  amount: AmountOfMoney;
  payment_status: SplitPaymentStatus;
}
