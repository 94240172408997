/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export interface ReportSummaryData {
  organisation_id?: string;
  label?: string;
  value?: number;
  variation?: number;
}
