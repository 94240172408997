/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type MetaStatus = typeof MetaStatus[keyof typeof MetaStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetaStatus = {
  succeeded: 'succeeded',
  failed: 'failed',
} as const;
