/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { CardStatus } from './cardStatus';

export interface Card {
  readonly id?: Uuid;
  readonly membership_id?: Uuid;
  card_reference?: string;
  is_physical?: boolean;
  card_status?: CardStatus;
  readonly monthly_limit?: number;
  readonly monthly_amount_used?: number;
  mcc_restriction_group_id?: Uuid;
  mid_restriction_group_id?: Uuid;
  mcc_restriction_group_reference?: number;
  mid_restriction_group_reference?: number;
  readonly physical_converted_at?: string;
  readonly pin_try_exceeded?: boolean;
  readonly created_at?: string;
  readonly updated_at?: string;
}
