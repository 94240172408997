/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type ReportTimeScale =
  typeof ReportTimeScale[keyof typeof ReportTimeScale];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportTimeScale = {
  YEAR: 'YEAR',
  MONTH: 'MONTH',
  WEEK: 'WEEK',
  DAY: 'DAY',
} as const;
