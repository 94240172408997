/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type JourneyType = typeof JourneyType[keyof typeof JourneyType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JourneyType = {
  ONE_WAY: 'ONE_WAY',
  ROUND_TRIP: 'ROUND_TRIP',
} as const;
