export const FIREBASE_STORAGE_FILES = {
  images: {
    DOA:
      'https://firebasestorage.googleapis.com/v0/b/skipr-production-eu.appspot.com/o/images%2Fdoa.jpg?alt=media&token=35eadc03-a5fe-4b67-8ed9-3dffdb376779',
    PASSPORT_EXAMPLE:
      'https://firebasestorage.googleapis.com/v0/b/skipr-production-eu.appspot.com/o/images%2Fpassport_template.jpg?alt=media&token=6fe5e18c-e359-48d4-a0e1-576676790b01',
    PROOF_ADDRESS_EXAMPLE:
      'https://firebasestorage.googleapis.com/v0/b/skipr-production-eu.appspot.com/o/images%2Fproof-address.jpg?alt=media&token=a91fa85e-f0f8-42a1-8266-1b5d43e0b1fa',
    ID_EXAMPLE:
      'https://firebasestorage.googleapis.com/v0/b/skipr-production-eu.appspot.com/o/images%2Fid-card.png?alt=media&token=9bbd69d1-9d66-4880-aaf9-98d681a36715',
    ID_EXAMPLE_BACK:
      'https://firebasestorage.googleapis.com/v0/b/skipr-production-eu.appspot.com/o/images%2Fspecimen_national_ID_BACK.jpg?alt=media&token=aff19ae5-8674-4c7f-a6d3-523f0f73fc45',
    ID_EXAMPLE_FRONT:
      'https://firebasestorage.googleapis.com/v0/b/skipr-production-eu.appspot.com/o/images%2Fspecimen_national_ID_FRONT.jpg?alt=media&token=caa73c44-26da-41cb-83e4-83cf88669118',
    BCE:
      'https://firebasestorage.googleapis.com/v0/b/skipr-production-eu.appspot.com/o/images%2Fbce_example.jpg?alt=media&token=59bb1fdc-54aa-44d1-a4a2-3cc85af4a62e',
    CONSTITUTION:
      'https://firebasestorage.googleapis.com/v0/b/skipr-production-eu.appspot.com/o/images%2Fcompany_constitution_example.jpg?alt=media&token=b3950625-6359-41f6-8eef-fec616db8257',
  },
  templates: {
    UPLOAD_EMPLOYEES:
      'https://firebasestorage.googleapis.com/v0/b/skipr-production-eu.appspot.com/o/templates%2Fupload-employees.csv?alt=media&token=75c965e3-8d08-46d7-8e5f-510ca8f2ebfa',
  },
};
