/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { AmountOfMoney } from './amountOfMoney';
import type { SplittableTransactionStatus } from './splittableTransactionStatus';

export interface SplittableTransaction {
  readonly id: Uuid;
  readonly merchant_name: string;
  /** This is populated when user identifies transaction as ticket/subscription */
  program_id?: Uuid;
  /** This is populated when user identifies transaction as ticket/subscription */
  product_id?: Uuid;
  /** This is populated when user identifies transaction as ticket/subscription */
  service_id?: Uuid;
  /** This field is populated when related expense is created */
  expense_id?: Uuid;
  /** This is populated after user identifies transaction as ticket/subscription */
  readonly employer_reimbursement_ratio?: number | null;
  /** This is populated after user identifies transaction as ticket/subscription */
  readonly employee_contribution_amount?: AmountOfMoney;
  /** indicates current status of splittable transaction */
  readonly status: SplittableTransactionStatus;
  /** Settled amount */
  readonly settled_amount: AmountOfMoney;
  /** Timestamp of transaction authorization */
  readonly authorized_at: string;
}
