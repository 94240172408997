/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

/**
 * search on merchant first and last_name
 */
export type SearchMerchantNameParameter = string;
