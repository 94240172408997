/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export interface ReportExpensesSummaryData {
  label?: string;
  time_scale_value?: number;
  percent?: number;
  variation?: number;
}
