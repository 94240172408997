/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';
import type { QueryGroupIdParameter } from './component-parameters/queryGroupIdParameter';
import type { QueryPolicyTypeParameter } from './queryPolicyTypeParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';

export type ListProgramExpensePoliciesParams = {
  program_id: Uuid;
  group_id?: QueryGroupIdParameter;
  policy_type?: QueryPolicyTypeParameter;
  page_number?: QueryPageNumberParameter;
  page_size?: QueryPageSizeParameter;
};
