/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type PolicyLevel = typeof PolicyLevel[keyof typeof PolicyLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PolicyLevel = {
  SERVICE: 'SERVICE',
  PROGRAM_GROUP: 'PROGRAM_GROUP',
  PROGRAM: 'PROGRAM',
} as const;
