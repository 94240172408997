/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { PolicyConditionField } from './policyConditionField';
import type { PolicyConditionStatement } from './policyConditionStatement';
import type { PolicyConditionBetween } from './policyConditionBetween';

export interface PolicyCondition {
  field: PolicyConditionField;
  statement: PolicyConditionStatement;
  between?: PolicyConditionBetween;
}
