/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { QueryOrganisationIdParameter } from './component-parameters/queryOrganisationIdParameter';
import type { QueryProgramStatusParameter } from './component-parameters/queryProgramStatusParameter';
import type { QueryPageNumberParameter } from './component-parameters/queryPageNumberParameter';
import type { QueryPageSizeParameter } from './component-parameters/queryPageSizeParameter';
import type { QuerySortDefinitionParameter } from './component-parameters/querySortDefinitionParameter';
import type { QueryQParameter } from './component-parameters/queryQParameter';

export type ProgramsGetParams = {
  organisation_id?: QueryOrganisationIdParameter;
  program_status?: QueryProgramStatusParameter;
  page_number?: QueryPageNumberParameter;
  page_size?: QueryPageSizeParameter;
  sort?: QuerySortDefinitionParameter;
  q?: QueryQParameter;
};
