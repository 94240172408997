/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type Platform = typeof Platform[keyof typeof Platform];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Platform = {
  MOBILE_APP_MOBILITY: 'MOBILE_APP_MOBILITY',
  MOBILE_APP_MAAS: 'MOBILE_APP_MAAS',
  WEB_DASHBOARD_MOBILITY: 'WEB_DASHBOARD_MOBILITY',
  WEB_DASHBOARD_ONBOARDING: 'WEB_DASHBOARD_ONBOARDING',
} as const;
