/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type SocialSecretaryDataSocialSecretaryName =
  typeof SocialSecretaryDataSocialSecretaryName[keyof typeof SocialSecretaryDataSocialSecretaryName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocialSecretaryDataSocialSecretaryName = {
  PARTENA: 'PARTENA',
  ATTENTIA: 'ATTENTIA',
} as const;
