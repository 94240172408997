/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Uuid } from './component-common/uuid';

export type CheckPolicyRequestAllOf = {
  policy_id?: Uuid;
};
