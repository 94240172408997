/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import type { Service } from './service';
import type { ProgramProduct } from './programProduct';
import type { SplitPaymentConfiguration } from './splitPaymentConfiguration';

export interface ServiceDefinition {
  service_details: Service;
  related_products?: ProgramProduct[];
  split_payment_config?: SplitPaymentConfiguration;
}
