/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  ResetPasswordBody,
  ForgotPasswordBody,
  GetConfigurationByPhoneNumberParams,
  GetConfigurationByEmailParams,
  GetConfigurationByTokenParams,
  SetAuthenticationMappingRequest,
} from '../../models';
import type { GetConfigurationResponse } from '../../models/component-auth';
import type { Phone, Email } from '../../models/component-common';
import { customInstance } from '../../config';

/**
 * This path is rate limited to 10 failing calls per minute.
Once threshold is reached, the IP will be blocked for 1 minute.
The limit is shared between all calls of /web/auth and /web/v2/auth

 */
export const resetPassword = (resetPasswordBody: ResetPasswordBody) => {
  return customInstance<void>({
    url: `/web/v2/auth/reset-password`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: resetPasswordBody,
  });
};

export type ResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof resetPassword>>
>;
export type ResetPasswordMutationBody = ResetPasswordBody;
export type ResetPasswordMutationError = unknown;

export const useResetPassword = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetPassword>>,
    TError,
    { data: ResetPasswordBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetPassword>>,
    { data: ResetPasswordBody }
  > = (props) => {
    const { data } = props ?? {};

    return resetPassword(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof resetPassword>>,
    TError,
    { data: ResetPasswordBody },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * This path is rate limited to 10 failing calls per minute.
Once threshold is reached, the IP will be blocked for 1 minute.
The limit is shared between all calls of /web/auth and /web/v2/auth

 */
export const forgotPassword = (forgotPasswordBody: ForgotPasswordBody) => {
  return customInstance<void>({
    url: `/web/v2/auth/forgot-password`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: forgotPasswordBody,
  });
};

export type ForgotPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof forgotPassword>>
>;
export type ForgotPasswordMutationBody = ForgotPasswordBody;
export type ForgotPasswordMutationError = unknown;

export const useForgotPassword = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forgotPassword>>,
    TError,
    { data: ForgotPasswordBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof forgotPassword>>,
    { data: ForgotPasswordBody }
  > = (props) => {
    const { data } = props ?? {};

    return forgotPassword(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof forgotPassword>>,
    TError,
    { data: ForgotPasswordBody },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * This path is rate limited to 10 failing calls per minute.
Once threshold is reached, the IP will be blocked for 1 minute.
The limit is shared between all calls of /web/auth and /web/v2/auth

 */
export const getConfigurationByPhoneNumber = (
  phoneNumber: Phone,
  params?: GetConfigurationByPhoneNumberParams,
  signal?: AbortSignal,
) => {
  return customInstance<GetConfigurationResponse>({
    url: `/web/v2/auth/configuration/phone_number/${phoneNumber}`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetConfigurationByPhoneNumberQueryKey = (
  phoneNumber: Phone,
  params?: GetConfigurationByPhoneNumberParams,
) => [
  `/web/v2/auth/configuration/phone_number/${phoneNumber}`,
  ...(params ? [params] : []),
];

export type GetConfigurationByPhoneNumberQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConfigurationByPhoneNumber>>
>;
export type GetConfigurationByPhoneNumberQueryError = unknown;

export const useGetConfigurationByPhoneNumber = <
  TData = Awaited<ReturnType<typeof getConfigurationByPhoneNumber>>,
  TError = unknown,
>(
  phoneNumber: Phone,
  params?: GetConfigurationByPhoneNumberParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConfigurationByPhoneNumber>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetConfigurationByPhoneNumberQueryKey(phoneNumber, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getConfigurationByPhoneNumber>>
  > = ({ signal }) =>
    getConfigurationByPhoneNumber(phoneNumber, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getConfigurationByPhoneNumber>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!phoneNumber,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Make sure to URL-encode the email!

This path is rate limited to 10 failing calls per minute.
Once threshold is reached, the IP will be blocked for 1 minute.
The limit is shared between all calls of /web/auth and /web/v2/auth

 */
export const getConfigurationByEmail = (
  email: Email,
  params?: GetConfigurationByEmailParams,
  signal?: AbortSignal,
) => {
  return customInstance<GetConfigurationResponse>({
    url: `/web/v2/auth/configuration/email/${email}`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetConfigurationByEmailQueryKey = (
  email: Email,
  params?: GetConfigurationByEmailParams,
) => [`/web/v2/auth/configuration/email/${email}`, ...(params ? [params] : [])];

export type GetConfigurationByEmailQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConfigurationByEmail>>
>;
export type GetConfigurationByEmailQueryError = unknown;

export const useGetConfigurationByEmail = <
  TData = Awaited<ReturnType<typeof getConfigurationByEmail>>,
  TError = unknown,
>(
  email: Email,
  params?: GetConfigurationByEmailParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConfigurationByEmail>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetConfigurationByEmailQueryKey(email, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getConfigurationByEmail>>
  > = ({ signal }) => getConfigurationByEmail(email, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getConfigurationByEmail>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!email, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Please use get_configuration_by_email since JWT token already carries an email frontend can access and use
Make sure to pass invitation token as X-Authorization header

This path is rate limited to 10 failing calls per minute.
Once threshold is reached, the IP will be blocked for 1 minute.
The limit is shared between all calls of /web/auth and /web/v2/auth

 * @deprecated
 */
export const getConfigurationByToken = (
  params?: GetConfigurationByTokenParams,
  signal?: AbortSignal,
) => {
  return customInstance<GetConfigurationResponse>({
    url: `/web/v2/auth/configuration/token`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetConfigurationByTokenQueryKey = (
  params?: GetConfigurationByTokenParams,
) => [`/web/v2/auth/configuration/token`, ...(params ? [params] : [])];

export type GetConfigurationByTokenQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConfigurationByToken>>
>;
export type GetConfigurationByTokenQueryError = unknown;

export const useGetConfigurationByToken = <
  TData = Awaited<ReturnType<typeof getConfigurationByToken>>,
  TError = unknown,
>(
  params?: GetConfigurationByTokenParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConfigurationByToken>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetConfigurationByTokenQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getConfigurationByToken>>
  > = ({ signal }) => getConfigurationByToken(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getConfigurationByToken>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Creates user specific authentication mapping between Skipr user identity and SSO identity.

Make sure to pass invitation token as X-Authorization header

This path is rate limited to 10 failing calls per minute.
Once threshold is reached, the IP will be blocked for 1 minute.
The limit is shared between all calls of /web/auth and /web/v2/auth

 */
export const setAuthMapping = (
  setAuthenticationMappingRequest: SetAuthenticationMappingRequest,
) => {
  return customInstance<void>({
    url: `/web/v2/auth/configuration`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: setAuthenticationMappingRequest,
  });
};

export type SetAuthMappingMutationResult = NonNullable<
  Awaited<ReturnType<typeof setAuthMapping>>
>;
export type SetAuthMappingMutationBody = SetAuthenticationMappingRequest;
export type SetAuthMappingMutationError = unknown;

export const useSetAuthMapping = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAuthMapping>>,
    TError,
    { data: SetAuthenticationMappingRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setAuthMapping>>,
    { data: SetAuthenticationMappingRequest }
  > = (props) => {
    const { data } = props ?? {};

    return setAuthMapping(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof setAuthMapping>>,
    TError,
    { data: SetAuthenticationMappingRequest },
    TContext
  >(mutationFn, mutationOptions);
};
