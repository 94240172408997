/**
 * Generated by orval v6.9.5 🍺
 * Do not edit manually.
 * Mobility Policy API
 * Mobility policies
 * OpenAPI spec version: 1.0
 */

export type SubscriptionStatus =
  typeof SubscriptionStatus[keyof typeof SubscriptionStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionStatus = {
  NOT_STARTED: 'NOT_STARTED',
  VALIDATED: 'VALIDATED',
  PENDING: 'PENDING',
  REFUSED: 'REFUSED',
} as const;
